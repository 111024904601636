<template>
  <div>
    <div class="px-4 pb-4 text-caption text-sm-body-2">
      <div class="d-flex">
        <div>
          <p class="d-flex">
            <span class="text-no-wrap">点検日：</span>
            <span>{{ formatDate(result.date, "YYYY/MM/DD") }}</span>
          </p>
          <p class="d-flex">
            <span class="text-no-wrap">OK数：</span>
            <span>{{ result.okCount }}</span>
          </p>
          <p class="d-flex">
            <span class="text-no-wrap">NG数：</span>
            <span :class="result.ngCount > 0 ? ngClass : ''">{{ result.ngCount }}</span>
          </p>
          <p class="d-flex">
            <span class="text-no-wrap">未点検数：</span>
            <span>{{ result.uncheckedCount }}</span>
          </p>
        </div>
        <v-spacer></v-spacer>
        <slot />
      </div>
      <p class="d-flex">
        <span class="text-no-wrap">最終送信日時：</span>
        <span>{{ formatDate(result.lastSentAt, "YYYY/MM/DD HH:mm") }}</span>
      </p>
      <p class="d-flex">
        <span class="text-no-wrap">確認日時：</span>
        <span>
          <span>{{
            result.confirmedAt === "点検なし"
              ? ""
              : formatDate(result.confirmedAt, "YYYY/MM/DD HH:mm")
          }}</span>
          <span v-if="result.confirmerName">（{{ result.confirmerName }}）</span>
        </span>
      </p>
      <p class="d-flex">
        <span class="text-no-wrap">承認日時：</span>
        <span>
          <span>{{
            result.approvedAt === "点検なし"
              ? ""
              : formatDate(result.approvedAt, "YYYY/MM/DD HH:mm")
          }}</span>
          <span v-if="result.approverName">（{{ result.approverName }}）</span>
        </span>
      </p>
    </div>

    <p class="mb-1 pl-4 text-caption text-sm-body-2">詳細結果</p>
    <v-card class="pa-2 mb-4" outlined>
      <v-data-table
        :headers="$vuetify.breakpoint.xs ? xsHeaders : headers"
        :items="result.checkItems"
        :items-per-page="-1"
        no-data-text="データがありません"
        disable-sort
        :hide-default-header="$vuetify.breakpoint.xs"
        hide-default-footer
        :mobile-breakpoint="null"
      >
        <template #[`item.questionContent`]="{ item, index }">
          <p class="grey--text" style="font-size: 11px">
            <span>{{ item.category }}</span>
            <span v-if="item.category && item.solution"> : {{ item.solution }}</span>
          </p>
          <div class="d-flex">
            <div style="min-width: 24px">{{ index + 1 }}.</div>
            <span v-if="item.referenceService">{{ services[item.referenceService].name }}</span>
            <span v-else>{{ item.questionContent }}</span>
          </div>
        </template>

        <template #[`item.sentAt`]="{ item }">
          <p v-if="item.sentAt" class="text-caption">
            {{ formatDate(new Date(item.sentAt), "HH:mm") }}
          </p>
          <p class="grey--text" style="font-size: 11px">{{ item.sender }}</p>
        </template>

        <template #[`item.checkResult`]="{ item }">
          <template v-if="item.referenceService">
            <div class="text-no-wrap">
              <span class="grey--text text-caption">OK </span>
              <span class="mr-1 text-subtitle-1 font-weight-regular">{{
                result.serviceResult[item.referenceService].okCount
              }}</span>
              <span class="grey--text text-caption">NG </span>
              <span :class="getNGClass(item.referenceService)">{{
                result.serviceResult[item.referenceService].ngCount
              }}</span>
            </div>
          </template>
          <template v-else>
            <span v-if="!item.checkResult" class="grey--text">未点検</span>
            <span v-else :class="item.checkResult == 'NG' ? 'red--text font-weight-bold' : ''">
              {{ item.checkResult }}
            </span>
          </template>
        </template>

        <template #[`item.xsContent`]="{ item }">
          <div class="py-1 text-caption">
            <p class="grey--text" style="font-size: 11px">
              <span>{{ item.category }}</span>
              <span v-if="item.category && item.solution"> : {{ item.solution }}</span>
              <span>{{ item.solution }}</span>
            </p>
            <div class="d-flex">
              <div style="min-width: 24px">{{ result.checkItems.indexOf(item) + 1 }}.</div>
              <template v-if="item.referenceService">
                {{ services[item.referenceService].name }}
              </template>
              <template v-else>{{ item.questionContent }}</template>
            </div>
            <div class="pt-2 d-flex align-end">
              <div v-if="item.sentAt">
                <p>点検時間：{{ formatDate(item.sentAt, "HH:mm") }}</p>
                <p class="grey--text" style="font-size: 11px">{{ item.sender }}</p>
              </div>
              <v-spacer></v-spacer>
              <div>
                <template v-if="item.referenceService">
                  <span>点検結果：</span>
                  <span class="mr-1 text--secondary">OK</span>
                  <span class="mr-1 text-subtitle-1 font-weight-regular">{{
                    result.serviceResult[item.referenceService].okCount
                  }}</span>
                  <span class="mr-1 text--secondary">NG</span>
                  <span :class="getNGClass(item.referenceService)">{{
                    result.serviceResult[item.referenceService].ngCount
                  }}</span>
                </template>
                <template v-else>
                  <span v-if="!item.checkResult" class="grey--text">未点検</span>
                  <template v-else>
                    <span>点検結果：</span>
                    <span :class="item.checkResult == 'NG' ? ngClass : ''">{{
                      item.checkResult
                    }}</span>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";

export default {
  props: {
    result: Object,
    preview: Boolean,
  },
  mixins: [calcDate],
  data: () => ({
    headers: [
      { text: "点検項目", value: "questionContent" },
      { text: "点検時間", value: "sentAt", width: 120 },
      { text: "点検結果", value: "checkResult", align: "center", width: 80 },
    ],
    xsHeaders: [{ text: "点検項目", value: "xsContent" }],
    services: {
      personalHygiene: { name: "個人衛生点検", icon: "mdi-hand-wash-outline" },
      internalTemperature: { name: "庫内温度点検", icon: "icon-fridge-thermometer" },
      facility: { name: "機械点検", icon: "icon-facility" },
      clean: { name: "清掃点検", icon: "mdi-broom" },
      weightflow: { name: "重量点検", icon: "mdi-scale" },
      inspection: { name: "異物探知", icon: "mdi-magnify-expand" },
      acceptance: { name: "受入管理", icon: "mdi-cart-check" },
    },
    ngClass: "red--text font-weight-bold",
  }),
  computed: {
    /**
     * 個別点検結果がNGの場合のフォントの色取得
     * @param {string} service 個別アプリ名
     * @return {string} クラス名
     */
    getNGClass() {
      return function (service) {
        const count = this.result.serviceResult[service].ngCount;
        if (count > 0) return "mr-1 text-subtitle-1 red--text font-weight-bold";
        return "mr-1 text-subtitle-1";
      };
    },
  },
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0;
}

::v-deep .v-data-table__wrapper table thead tr th {
  padding: 0 8px;
  height: 42px !important;
}

::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
  height: 42px !important;
}

.v-data-table ::v-deep .v-data-table__wrapper table .v-data-table-header-mobile {
  display: none;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row {
  min-height: 32px;
  font-size: 12px;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row__header {
  font-weight: 400;
  white-space: nowrap;
}
</style>
