<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row no-gutters>
      <v-col class="pa-4" cols="5" sm="4" align-self="center">
        <span class="text-subtitle-1 text-sm-h6">レポート</span>
      </v-col>
      <v-col class="px-4 pt-2 pb-3" cols="7" sm="4" align-self="center">
        <MenuMonthPicker :month="month" @update="updateMonth" />
      </v-col>
      <v-col class="px-4 text-end" v-if="$vuetify.breakpoint.smAndUp" sm="4" align-self="center">
        <CardPdfInfo :month="month" :apps="apps.filter((e) => e.isUsing)" />
      </v-col>
    </v-row>

    <!-- サイズSM以上 -->
    <v-row v-if="$vuetify.breakpoint.smAndUp" no-gutters>
      <v-col :class="isHorizontal ? 'pr-1' : 'pb-1'" :cols="isHorizontal ? '4' : '12'">
        <v-card class="pa-4" flat rounded="lg">
          <!-- 縦テーブル -->
          <v-data-table
            v-if="isHorizontal"
            :headers="headers"
            :items="apps"
            :items-per-page="-1"
            :loading="loading"
            loading-text="読込中"
            disable-sort
            fixed-header
            hide-default-footer
            :mobile-breakpoint="null"
            :height="getGraphHeight + 298"
          >
            <template #item="{ item }">
              <tr
                :class="selectedApp.key == item.key ? 'tertiary' : ''"
                @click="selectApp(item.key)"
              >
                <td>
                  <div class="d-flex align-center">
                    <v-icon style="width: 30px" size="28">{{ item.icon }}</v-icon>
                    <span class="ml-2 text-subtitle-2 text-md-subtitle-1">{{ item.appName }}</span>
                  </div>
                </td>
                <td class="text-center">
                  <CircularRate
                    :isCheck="item.checkCount > 0"
                    :value="item.approvalRate"
                    :color="item.approvalRate == 100 ? 'primary' : 'red'"
                  />
                </td>
                <td class="text-center">
                  <CircularRate
                    :isCheck="item.checkCount > 0"
                    :value="item.ngRate"
                    :color="item.ngRate == 0 ? 'primary' : 'red'"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>

          <!-- 横テーブル -->
          <v-data-table
            v-else
            class="table-vertical"
            :headers="verticalHeaders"
            :items="verticalItems"
            :items-per-page="-1"
            :loading="loading"
            loading-text="読込中"
            disable-sort
            fixed-header
            hide-default-footer
            :mobile-breakpoint="null"
            height="156"
          >
            <template #[`header.${head.value}`]="{}" v-for="head in verticalHeaders">
              <div
                class="fill-height d-flex justify-center align-center"
                :class="selectedApp.key == head.value ? 'tertiary' : ''"
                :key="head.value"
                @click="selectApp(head.value)"
              >
                <v-icon>{{ head.text }}</v-icon>
              </div>
            </template>
            <template #item="{ item }">
              <tr>
                <td>{{ item.category }}</td>
                <td
                  class="px-2 text-center"
                  :class="selectedApp.key == app.key ? 'tertiary' : ''"
                  v-for="app in apps"
                  :key="app.value"
                  @click="selectApp(app.key)"
                >
                  <CircularRate
                    :isCheck="!isNaN(item[app.key])"
                    :value="item[app.key]"
                    :color="
                      (item.category == '承認率' && item[app.key] == 100) ||
                      (item.category == 'NG比率' && item[app.key] == 0)
                        ? 'primary'
                        : 'red'
                    "
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <!-- チャート部分 -->
      <v-col :class="isHorizontal ? 'pl-1' : 'pt-1'" :cols="isHorizontal ? '8' : '12'">
        <div class="pa-4 d-flex" style="height: 64px">
          <v-icon class="mr-1" style="width: 24px">{{ selectedApp.icon }}</v-icon>
          <span class="text-h6">{{ selectedApp.appName }}</span>
          <v-spacer></v-spacer>
          <div class="d-flex align-center" v-if="selectedApp.key == 'personalHygiene'">
            <v-checkbox
              class="mx-6 my-0"
              v-model="canSelect"
              :hide-details="true"
              :ripple="false"
              @change="selectApp('personalHygiene')"
            >
              <template v-slot:label>
                <span class="text-caption text--primary">所属選択</span>
              </template>
            </v-checkbox>
            <v-select
              class="text-body-2"
              v-model="selectedPosition"
              :items="positionResults"
              item-text="position"
              return-object
              :disabled="!canSelect"
              menu-props="auto"
              hide-details
              dense
              style="width: 150px"
              @change="selectedApp = $event"
            />
          </div>
        </div>

        <v-expansion-panels v-model="panels" mandatory flat>
          <!-- グラフ -->
          <v-expansion-panel class="rounded-lg">
            <v-expansion-panel-header class="px-4 py-0">グラフ</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text class="px-0 pt-0 pb-2 d-flex justify-center">
                <div class="mx-3 text-center">
                  <span>総点検数</span><br />
                  <span class="text-h2">{{ selectedApp.checkCount }}</span>
                  <span class="ml-1">件</span>
                </div>
                <div class="mx-3 text-center">
                  <span>承認完了</span><br />
                  <span class="text-h2">{{ selectedApp.approvalCount }}</span>
                  <span class="ml-1">件</span>
                </div>
                <div class="mx-3 text-center">
                  <span>NG数</span><br />
                  <span class="text-h2" :class="selectedApp.ngCount > 0 ? 'red--text' : ''">{{
                    selectedApp.ngCount
                  }}</span>
                  <span class="ml-1">件</span>
                </div>
              </v-card-text>
              <v-sheet
                v-if="loading"
                class="d-flex justify-center align-center"
                :height="getGraphHeight"
              >
                <div class="d-flex flex-column align-center" style="width: 200px">
                  <span class="text-subtitle-1">グラフ作成中…</span>
                  <v-progress-linear indeterminate color="primary" height="5" />
                </div>
              </v-sheet>
              <div v-else class="px-4">
                <Chart
                  :month="month"
                  :checkCounts="selectedApp.dayCheckCounts"
                  :ngCounts="selectedApp.dayNgCounts"
                  :styles="{ height: `${getGraphHeight}px` }"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- 点検結果一覧 -->
          <v-expansion-panel class="mt-2 rounded-lg">
            <v-expansion-panel-header class="px-4 py-0 d-flex" @click="selectedResult = ''">
              <span>
                <span>点検結果</span>
                <template v-if="selectedApp.results">
                  <span class="ml-4 text--secondary">{{
                    isNgOnly
                      ? selectedApp.results.filter((e) => !e.isNormal).length
                      : selectedApp.results.length
                  }}</span>
                  <span>件</span>
                </template>
              </span>
              <v-spacer></v-spacer>
              <v-sheet max-width="200">
                <v-checkbox class="ml-10 my-0" v-model="isNgOnly" hide-details :ripple="false">
                  <template v-slot:label>
                    <span class="text-body-2 text--primary">NG点検のみ表示</span>
                  </template>
                </v-checkbox>
              </v-sheet>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-sheet class="px-4 py-2 transparent" :min-height="getGraphHeight + 90">
                <TableResults
                  :appName="selectedApp.key"
                  :items="
                    isNgOnly ? selectedApp.results.filter((e) => !e.isNormal) : selectedApp.results
                  "
                  :selectedResult="selectedResult"
                  :loading="loading"
                  :height="getGraphHeight + 74"
                  @clickComment="
                    selectedComment = $event;
                    panels = 2;
                  "
                  @clickTransition="transitionPage(selectedApp, $event)"
                />
              </v-sheet>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- 特記事項一覧 -->
          <v-expansion-panel class="mt-2 rounded-lg">
            <v-expansion-panel-header class="px-4 py-0 d-flex" @click="selectedComment = ''">
              <span>
                <span>特記事項</span>
                <template v-if="selectedApp.comments">
                  <span class="ml-4 text--secondary">{{ selectedApp.comments.length }}件</span>
                </template>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider></v-divider>
              <ListComments
                :comments="selectedApp.comments"
                :selectedComment="selectedComment"
                :height="getGraphHeight + 90"
                @click="
                  selectedResult = $event;
                  panels = 1;
                "
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- 縦スマホ時 -->
    <template v-else>
      <div v-if="loading" class="px-4">
        <v-sheet
          class="d-flex flex-column justify-center align-center"
          :height="$vuetify.breakpoint.height - 196"
          width="100%"
          rounded="lg"
        >
          <div class="text-center" style="width: 200px">
            点検票集計中…
            <v-progress-linear indeterminate color="primary" height="5" />
          </div>
        </v-sheet>
      </div>
      <div v-else class="d-flex align-center">
        <v-btn
          class="mr-n9 white"
          icon
          elevation="4"
          @click="$refs.window.prev()"
          style="z-index: 10"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-window
          ref="window"
          v-model="window"
          continuous
          @change="selectApp(apps[$event].key)"
          style="width: 100%"
        >
          <v-window-item class="px-4" v-for="app in apps" :key="app.appName">
            <v-sheet
              class="pa-4"
              :height="$vuetify.breakpoint.height - 196"
              min-height="471"
              rounded="lg"
            >
              <v-row class="py-2" no-gutters justify="center">
                <v-col class="d-flex justify-center align-center" cols="6">
                  <v-select
                    class="pr-0 ma-0 text-h6"
                    v-model="selectedApp"
                    :items="apps"
                    item-text="appName"
                    return-object
                    menu-props="offset-y"
                    hide-details
                    append-icon=""
                    dense
                    @change="window = apps.indexOf($event)"
                  >
                    <template #selection="{ item }">
                      <div
                        class="pr-2 d-flex justify-center align-end primary--text"
                        style="width: 100%"
                      >
                        <v-icon color="primary" style="width: 30px">{{ item.icon }}</v-icon>
                        {{ item.appName }}
                      </div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="px-4 py-2 mb-1 d-flex" v-if="app.key == 'personalHygiene'" no-gutters>
                <v-checkbox
                  class="mr-6 my-0"
                  v-model="canSelect"
                  :hide-details="true"
                  :ripple="false"
                  @change="(e) => (e ? (selectedApp = selectedPosition) : (selectedApp = app))"
                >
                  <template v-slot:label>
                    <span class="text-caption text--primary">所属選択</span>
                  </template>
                </v-checkbox>
                <v-select
                  class="text-body-2"
                  v-model="selectedPosition"
                  :items="positionResults"
                  item-text="position"
                  menu-props="offset-y"
                  return-object
                  :disabled="!canSelect"
                  hide-details
                  dense
                  style="width: 150px"
                  @change="selectedApp = $event"
                />
              </v-row>
              <v-row
                class="text--secondary"
                no-gutters
                align="center"
                :style="{
                  height: `calc(100% - ${app.key == 'personalHygiene' ? '96' : '48'}px`,
                  'min-height': '320px',
                }"
              >
                <v-col cols="12" style="height: 50%">
                  <v-row class="py-4" no-gutters align="center" style="height: 100%">
                    <v-col cols="6">
                      <p class="mb-1 text-center">承認率</p>
                      <v-sheet class="d-flex justify-center align-center transparent" height="100">
                        <CircularRate
                          :isCheck="selectedApp.checkCount > 0"
                          :value="selectedApp.approvalRate"
                          :color="selectedApp.approvalRate == 100 ? 'primary' : 'red'"
                          large
                        />
                      </v-sheet>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="6">
                      <p class="mb-1 text-center">NG比率</p>
                      <v-sheet class="d-flex justify-center align-center transparent" height="100">
                        <CircularRate
                          :isCheck="selectedApp.checkCount > 0"
                          :value="selectedApp.ngRate"
                          :color="selectedApp.ngRate == 0 ? 'primary' : 'red'"
                          large
                        />
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
                <v-col cols="12" style="height: 50%">
                  <v-row class="pt-2" no-gutters align-content="stretch" style="height: 100%">
                    <v-col class="px-4 py-2 d-flex" cols="12" style="height: calc(100% / 3)">
                      総点検数
                      <v-spacer></v-spacer>
                      <div class="d-flex align-end">
                        <span class="text-h4">{{ selectedApp.checkCount }}</span>
                        <span class="ml-1">件</span>
                      </div>
                    </v-col>
                    <v-col cols="12"><v-divider></v-divider></v-col>
                    <v-col class="px-4 py-2 d-flex" cols="12" style="height: calc(100% / 3)">
                      承認完了
                      <v-spacer></v-spacer>
                      <div class="d-flex align-end">
                        <span class="text-h4">{{ selectedApp.approvalCount }}</span>
                        <span class="ml-1">件</span>
                      </div>
                    </v-col>
                    <v-col cols="12"><v-divider></v-divider></v-col>
                    <v-col class="px-4 py-2 d-flex" cols="12" style="height: calc(100% / 3)">
                      NG数
                      <v-spacer></v-spacer>
                      <div class="d-flex align-end">
                        <span class="text-h4" :class="selectedApp.ngCount > 0 ? 'red--text' : ''">{{
                          selectedApp.ngCount
                        }}</span>
                        <span class="ml-1">件</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider></v-divider>
              </v-row>
            </v-sheet>
          </v-window-item>
        </v-window>
        <v-btn class="ml-n9 white" icon elevation="4" @click="$refs.window.next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </template>
  </v-container>
</template>

<script>
import moment from "moment";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import CardPdfInfo from "../organisms/CardPdfInfo";
import Chart from "../organisms/Chart";
import CircularRate from "../molecules/CircularRate";
import TableResults from "../organisms/TableResults";
import ListComments from "../organisms/ListComments";

export default {
  components: {
    CardPdfInfo,
    Chart,
    CircularRate,
    TableResults,
    ListComments,
  },
  mixins: [dbProcess, calcDate],
  data: () => ({
    isHorizontal: false,
    loading: true,
    month: "",
    window: 0,
    apps: [],
    headers: [
      { text: "アプリ名", value: "appName" },
      { text: "承認率", value: "approvalRate", align: "center" },
      { text: "NG比率", value: "ngRate", align: "center" },
    ],
    verticalItems: [],
    verticalHeaders: [{ text: "", value: "category" }],
    selectedApp: { results: [], comments: [] },
    dayData: {},
    canSelect: false,
    selectedPosition: {},
    positionResults: [],
    panels: 0,
    isNgOnly: true,
    selectedResult: "",
    selectedComment: "",
    message: "",
    messageDialog: false,
  }),
  created: function () {
    this.$emit("created");
    this.month = moment().format("YYYY/MM");
  },
  activated: async function () {
    await this.loadResult();
  },
  mounted: function () {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    /**
     * グラフの高さを取得
     * @return {number} 高さ
     */
    getGraphHeight() {
      let offset = 0;
      if (this.$vuetify.breakpoint.xs) {
        offset = this.selectedApp.key == "personalHygiene" ? 394 : 348;
      } else {
        offset = this.isHorizontal ? 522 : 718;
      }
      const height = this.$vuetify.breakpoint.height - offset;
      return height <= 240 ? 240 : height;
    },
  },
  methods: {
    onResize() {
      this.isHorizontal = document.documentElement.clientWidth > 800;
    },

    /**
     * DBから点検結果一覧を取得
     */
    async loadResult() {
      this.loading = true;
      const shop = this.$store.getters.getShop;

      // カレンダーの表示開始日、終了日を算出
      const startAt = new Date(`${this.month}/01 00:00:00`);
      const endAt = new Date(moment(startAt).add(1, "month").add(-1, "seconds"));

      // アプリ情報の取得
      const haccpApps = await this.getQueryDoc({
        collection: "appMenu",
        where: [
          { fieldPath: "category", opStr: "==", value: "haccp" },
          { fieldPath: "shouldSummarize", opStr: "==", value: true },
        ],
        order: [{ fieldPath: "order", directionStr: "asc" }],
      });

      // 特記事項情報の取得
      const commentDocs = await this.getQueryDoc({
        collection: "comments",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "registeredAt", directionStr: "asc" }],
        startAt: startAt,
        endAt: endAt,
      });
      const comments = commentDocs.map((e) => ({
        ...e,
        relationCheckResult: e.relationCheckResult ?? {},
      }));

      // アプリ別に点検結果を取得
      const criticalControlCategories = [...Array(5)].map((_, i) => `category0${i + 1}`);
      this.apps = await Promise.all(
        haccpApps.map(async (app) => {
          const results = [];
          for (const table of app.resultTables) {
            const resultDocs = await this.getQueryDoc({
              collection: table,
              where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
              order: [{ fieldPath: "registeredAt", directionStr: "asc" }],
              startAt: startAt,
              endAt: endAt,
            });

            for (const doc of resultDocs) {
              const result = {
                ...doc,
                date: moment(doc.registeredAt.seconds * 1000).format("YYYY-MM-DD"),
                isNormal: doc.isNormalForReport,
                isConfirmed: doc.confirmerName != "",
                isApproved: doc.approverName != "",
                sender: doc.name ?? doc.sender,
                sentAt: doc.answeredAt ?? doc.sentAt,
                registeredAt: doc.registeredAt,
                comment: {
                  relationCheckResult: {},
                  ...comments.find((e) => e.relationCheckResult.uid == doc.id),
                },
              };

              if (app.appName == "個人衛生") result.position = doc.position;

              if (app.appName == "日報") {
                result.checkItems = doc.checkItems;
                result.isNormal = !doc.checkItems.some((e) => e.checkResult == "NG");
              }

              if (app.appName == "重要管理") {
                result.checkItems = criticalControlCategories.map((category) => doc[category]);
                result.isNormal = !result.checkItems.some((e) => e.checkResult == "NG");
              }

              results.push(result);
            }
          }

          results.sort((a, b) => {
            if (a.sentAt.seconds > b.sentAt.seconds) return 1;
            if (a.sentAt.seconds < b.sentAt.seconds) return -1;
            return 0;
          });

          // 日ごとの情報を集計
          const dayCheckCounts = [];
          const dayNgCounts = [];
          for (let day = moment(startAt); day.unix() <= moment(endAt).unix(); day.add(1, "days")) {
            const date = moment(day).format("YYYY-MM-DD");

            if (app.appName == "日報" || app.appName == "重要管理") {
              const items = results.find((r) => r.date == date)?.checkItems;
              dayCheckCounts.push(items ? items.filter((r) => r.checkResult != "").length : 0);
              dayNgCounts.push(items ? items.filter((r) => r.checkResult == "NG").length : 0);
            } else {
              dayCheckCounts.push(results.filter((r) => r.date == date).length);
              dayNgCounts.push(results.filter((r) => r.date == date && !r.isNormal).length);
            }
          }

          return {
            appName: app.appName,
            icon: app.icon,
            key: app.key,
            origin: app.origin,
            summaryPath: app.summaryPath,
            isUsing: !!shop[app.key],
            comments: comments.filter((e) => e.serviceName == app.appName),
            results,
            checkCount:
              app.appName == "日報" || app.appName == "重要管理"
                ? dayCheckCounts.reduce((s, v) => s + v)
                : results.length,
            ngCount:
              app.appName == "日報" || app.appName == "重要管理"
                ? dayNgCounts.reduce((s, v) => s + v)
                : results.filter((e) => !e.isNormal).length,
            ngRate:
              app.appName == "日報" || app.appName == "重要管理"
                ? (dayNgCounts.reduce((s, v) => s + v) / dayCheckCounts.reduce((s, v) => s + v)) *
                  100
                : (results.filter((e) => !e.isNormal).length / results.length) * 100,
            approvalCount: results.filter((e) => e.isApproved).length,
            approvalRate: (results.filter((e) => e.isApproved).length / results.length) * 100,
            dayCheckCounts,
            dayNgCounts,
          };
        })
      );

      // 横並び用のヘッダー作成
      this.verticalHeaders = [{ text: "", value: "category" }];
      this.verticalHeaders.push(
        ...haccpApps.map((e) => ({ text: e.icon, value: e.key, width: 66 }))
      );

      // 横並び用の一覧作成
      this.verticalItems = ["承認率", "NG比率"].map((category) => {
        const appResult = {};
        for (const app of this.apps) {
          if (category == "承認率") appResult[app.key] = app.approvalRate;
          if (category == "NG比率") appResult[app.key] = app.ngRate;
        }
        return { category, ...appResult };
      });

      // 個人衛生は所属別に集計する
      const positions = shop.positions;
      const hygieneApp = this.apps.find((a) => a.key == "personalHygiene");
      for (const r of hygieneApp.results)
        if (!positions.includes(r.position)) positions.push(r.position);

      this.positionResults = positions.map((position) => {
        const results = hygieneApp.results.filter((r) => r.position == position);

        // 日ごとの情報を集計
        const dayCheckCounts = [];
        const dayNgCounts = [];
        for (let day = moment(startAt); day.unix() <= moment(endAt).unix(); day.add(1, "days")) {
          const date = moment(day).format("YYYY-MM-DD");
          dayCheckCounts.push(results.filter((r) => r.date == date).length);
          dayNgCounts.push(results.filter((r) => r.date == date && !r.isNormal).length);
        }

        return {
          appName: hygieneApp.appName,
          icon: hygieneApp.icon,
          key: hygieneApp.key,
          position,
          comments: comments.filter((e) => e.serviceName == hygieneApp.appName),
          results,
          checkCount: results.length,
          ngCount: results.filter((e) => !e.isNormal).length,
          ngRate: (results.filter((e) => !e.isNormal).length / results.length) * 100,
          approvalCount: results.filter((e) => e.isApproved).length,
          approvalRate: (results.filter((e) => e.isApproved).length / results.length) * 100,
          dayCheckCounts,
          dayNgCounts,
        };
      });
      const user = this.$store.getters.getUser;
      this.selectedPosition = this.positionResults.find((p) =>
        this.selectedPosition.position
          ? p.position == this.selectedPosition.position
          : p.position == positions.includes(user.position)
          ? user.position
          : positions[0]
      );

      this.selectedApp = this.selectedApp.appName
        ? this.apps.find((app) => app.appName == this.selectedApp.appName)
        : this.apps[0];

      if (this.selectedApp.key == "personalHygiene" && this.canSelect)
        this.selectedApp = this.selectedPosition;

      this.loading = false;
    },

    /**
     * 月変更があったらカレンダー更新
     * @param {string} month YYYY/MM
     */
    updateMonth(month) {
      this.month = month;
      this.loadResult();
    },

    /**
     * アプリ選択
     * @param {string} appName
     */
    selectApp(appName) {
      this.selectedApp =
        appName == "personalHygiene" && this.canSelect
          ? this.selectedPosition
          : this.apps.find((a) => a.key == appName);
    },

    /**
     * 各アプリの承認画面に遷移
     * @param {object} app アプリ情報
     * @param {string} date 選択した日付(YYYY-MM-DD)
     */
    transitionPage(app, date) {
      console.log(app);
      location.href = `${app.origin}/${this.$route.params.shopUID}/${
        app.summaryPath
      }?date=${date.replaceAll("-", "/")}`;
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table__wrapper table tbody tr td {
  height: 52px;
}

::v-deep .table-vertical .v-data-table__wrapper table thead tr th {
  height: 52px;
  padding: 0;
}

::v-deep
  .table-vertical
  .v-data-table__wrapper
  table
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent !important;
}

::v-deep .v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px;
}

::v-deep .v-expansion-panel-header {
  font-size: 16px;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}

::v-deep .v-data-table__wrapper table thead tr th {
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper table thead tr th:first-child,
::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 16px !important;
}

::v-deep .v-data-table__wrapper table thead tr th:last-child,
::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 16px !important;
}
</style>
