<template>
  <ButtonCreatePDF
    title="月報"
    :subTitle="`${month} アプリ別集計結果`"
    icon="mdi-calendar-month"
    large
  >
    <div v-for="app in apps" :key="app.key">
      <v-card class="my-2 pa-2" flat>
        <v-card-title>
          <v-icon class="mr-1" style="width: 24px">{{ app.icon }}</v-icon>
          {{ app.appName }}
        </v-card-title>
        <v-card-text class="px-0 py-4 d-flex justify-center">
          <div class="px-3 text-center">
            <p>総点検数</p>
            <span class="text-h2">{{ app.checkCount }}</span>
            <span class="ml-1">件</span>
          </div>
          <div class="px-3 text-center">
            <p>承認完了</p>
            <span class="text-h2">{{ app.approvalCount }}</span>
            <span class="ml-1">件</span>
          </div>
          <div class="px-3 text-center">
            <p>NG数</p>
            <span class="text-h2" :class="app.ngCount > 0 ? 'red--text' : ''">{{
              app.ngCount
            }}</span>
            <span class="ml-1">件</span>
          </div>
          <div class="px-3 text-center">
            <p>承認率</p>
            <v-sheet class="d-flex justify-center align-center text--secondary" height="60">
              <div v-if="app.checkCount > 0" class="d-flex align-baseline">
                <span class="text-h2" :class="app.approvalRate < 100 ? 'red--text' : ''">
                  {{ Math.round(app.approvalRate) }}
                </span>
                <span class="ml-1">%</span>
              </div>
              <span v-else class="grey--text">未点検</span>
            </v-sheet>
          </div>
          <div class="px-3 text-center">
            <p>NG比率</p>
            <v-sheet class="d-flex justify-center align-center text--secondary" height="60">
              <div v-if="app.checkCount > 0" class="d-flex align-baseline">
                <span class="text-h2" :class="app.ngRate > 0 ? 'red--text' : ''">
                  {{ Math.round(app.ngRate) }}
                </span>
                <span class="ml-1">%</span>
              </div>
              <span v-else class="grey--text">未点検</span>
            </v-sheet>
          </div>
        </v-card-text>
        <Chart
          :month="month"
          :checkCounts="app.dayCheckCounts"
          :ngCounts="app.dayNgCounts"
          :styles="{ height: `400px` }"
        />
      </v-card>

      <div class="px-4">
        <h4 class="pl-2">特記事項</h4>
        <v-divider class="mt-1"></v-divider>
        <ListComments :comments="app.comments" selectedComment="" />
      </div>

      <div class="html2pdf__page-break"></div>
    </div>
  </ButtonCreatePDF>
</template>

<script>
import Chart from "./Chart";
import ListComments from "./ListComments";

export default {
  props: {
    month: String,
    apps: Array,
  },
  components: {
    Chart,
    ListComments,
  },
  data: () => ({}),
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 8px;
}
</style>
