<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveData } = mixins;

export default {
  props: {
    month: String,
    checkCounts: Array,
    ngCounts: Array,
  },
  extends: Line,
  mixins: [reactiveData],
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            scaleLabel: { display: true, labelString: "日付" },
            ticks: {
              autoSkip: false, // 狭くてもラベルを省略しない
              maxRotation: 0, // 縦書きなので縦向きに固定
              minRotation: 0,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "点検数",
            },
            ticks: {
              stacked: true,
              min: 0,
              max: 0,
            },
          },
        ],
      },
      legend: {
        display: true,
        position: "top",
        labels: {
          generateLabels: (data) => {
            // 凡例の表示
            return data.tooltip._data.datasets
              .slice()
              .reverse()
              .map((data) => {
                return {
                  text: data.label,
                  fillStyle: data.backgroundColor,
                  lineWidth: 0,
                };
              });
          },
        },
      },
    },
  }),
  watch: {
    checkCounts() {
      this.updateChartData();
    },
    ngCounts() {
      this.updateChartData();
    },
  },
  mounted() {
    const month = this.month;
    this.options.tooltips = {
      intersect: false,
      titleFontColor: "#00000099",
      titleFontSize: 14,
      bodyFontColor: "#00000099",
      bodyFontSize: 14,

      // 吹き出しの背景色
      backgroundColor: "#fff",
      borderColor: this.$vuetify.theme.themes.light.primary,
      borderWidth: 1,

      // 吹き出し内の余白
      xPadding: 16,
      yPadding: 16,
      callbacks: {
        title: function (tooltipItem) {
          return `${month}/${("00" + (tooltipItem[0].index + 1)).slice(-2)}`;
        },
      },
    };

    this.chartData = {
      labels: this.checkCounts.map((e, i) => i + 1),
      datasets: [
        {
          label: "NG数",
          data: this.ngCounts,
          backgroundColor: "#ef9a9a",
          borderColor: "#ef5350",
          fill: false,
          borderWidth: 1,
          pointRadius: 2,
          lineTension: 0,
        },
        {
          label: "点検数",
          data: this.checkCounts,
          backgroundColor: this.$vuetify.theme.themes.light.secondary,
          borderColor: this.$vuetify.theme.themes.light.primary,
          fill: false,
          borderWidth: 1,
          pointRadius: 3,
          lineTension: 0,
        },
      ],
    };

    // Y軸の最大値の設定
    const max = Math.max(...this.checkCounts);
    this.options.scales.yAxes[0].ticks.max =
      max < 100 ? max + 10 - Number(max.toString().slice(-1)) : undefined;

    this.renderChart(this.chartData, this.options);
  },
  methods: {
    /**
     * グラフの再描画
     */
    updateChartData() {
      // データの更新
      const newChartData = Object.assign({}, this.chartData);
      newChartData.labels = this.checkCounts.map((e, i) => i + 1);
      newChartData.datasets[0].data = this.ngCounts;
      newChartData.datasets[1].data = this.checkCounts;
      this.chartData = newChartData;

      // Y軸の最大値の設定
      const max = Math.max(...this.checkCounts);
      this.options.scales.yAxes[0].ticks.max =
        max < 100 ? max + 10 - Number(max.toString().slice(-1)) : undefined;

      this.renderChart(this.chartData, this.options);
    },
  },
};
</script>

<style></style>
