<template>
  <v-sheet class="px-2 transparent">
    <div v-if="!comments || comments.length == 0" class="pa-2">
      <span class="grey--text text-caption">特記事項はありません</span>
    </div>
    <v-list class="overflow-y-auto" dense :height="height">
      <v-list-item
        class="pa-2 d-flex align-start"
        :class="selectedComment == comment.relationCheckResult.uid ? 'tertiary' : ''"
        v-for="(comment, i) in comments"
        :key="i"
        @click="$emit('click', comment.relationCheckResult.uid)"
      >
        <v-list-item-content class="pa-0 text-body-2">
          <v-list-item-title class="text-caption font-weight-light">
            <span class="mr-2 text-body-2 font-weight-bold">{{ comment.name }}</span>
            <span class="mr-2 text-caption text--secondary">
              {{ formatDate(comment.createdAt, "MM/DD HH:mm") }}
            </span>
            <v-chip
              v-if="comment.relationCheckResult.uid"
              small
              :color="comment.relationCheckResult.isNormal ? '' : 'red lighten-4'"
            >
              {{ `${comment.relationCheckResult.title} ${comment.relationCheckResult.sentAt}` }}
            </v-chip>
          </v-list-item-title>
          <v-list-item-subtitle class="ma-0 pb-2 d-flex justify-space-between">
            <div
              class="text--primary text-wrap"
              style="max-width: calc(100% - 100px); white-space: pre-wrap !important"
            >
              <span>{{ comment.content }}</span>
            </div>
            <div
              @click.stop="
                $refs.imgDialog.openDialog({
                  text: `送信日時：${formatDate(comment.createdAt, 'MM/DD HH:mm')}\n${
                    comment.content
                  }`,
                  url: comment.imgFileURL,
                  fileName: `${formatDate(comment.registeredAt, 'YYYYMMDD')}_${comment.id}.jpeg`,
                })
              "
            >
              <ImgThumbnail v-if="comment.imgFileURL" :src="comment.imgFileURL" size="60" />
            </div>
          </v-list-item-subtitle>

          <template v-for="(reply, i) in comment.replies">
            <v-list-item-content
              class="ml-2 px-2 mb-0"
              :key="`${comment.id}_${i}`"
              style="border-left: 1px solid #0000001f"
            >
              <v-list-item-title class="d-flex align-center">
                <span class="mr-2 text-body-2 font-weight-bold">{{ reply.name }}</span>
                <span class="mr-2 text-caption text--secondary">
                  {{ formatDate(reply.repliedAt, "MM/DD HH:mm") }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle class="d-flex justify-space-between">
                <div
                  class="text--primary text-wrap"
                  style="max-width: calc(100% - 116px); white-space: pre-wrap !important"
                >
                  <span>{{ reply.content }}</span>
                </div>
                <div
                  @click.stop="
                    $refs.imgDialog.openDialog({
                      text: `送信日時：${formatDate(reply.repliedAt, 'MM/DD HH:mm')}\n${
                        reply.content
                      }`,
                      url: reply.imgFileURL,
                      fileName: `${formatDate(comment.registeredAt, 'YYYYMMDD')}_${comment.id}_${
                        i + 1
                      }.jpeg`,
                    })
                  "
                >
                  <ImgThumbnail v-if="reply.imgFileURL" :src="reply.imgFileURL" size="60" />
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <DialogImg ref="imgDialog" />
  </v-sheet>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";

export default {
  props: {
    height: Number,
    comments: Array,
    selectedComment: String,
  },
  mixins: [calcDate],
  data: () => ({}),
};
</script>
