<template>
  <v-dialog v-model="dialog" max-width="1000">
    <v-card class="pa-2 pa-sm-4" color="#f5f5f5">
      <v-card-title class="pa-4 d-flex align-baseline">
        <span class="mr-5 text-subtitle-1 text-sm-h6">点検結果詳細</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-subtitle v-if="warningMessage" class="px-4 pb-4">
        <span class="text-caption primary--text">{{ warningMessage }}</span>
      </v-card-subtitle>

      <!-- 詳細内容 -->
      <CheckResultDetail :result="item">
        <ButtonCreatePDF title="日報" subTitle="点検結果詳細" icon="mdi-calendar-today">
          <CheckResultDetail :result="item" preview />
          <div class="py-2">
            <p class="mb-1 pl-4 text-caption text-sm-body-2">特記事項</p>
            <ListComments :comments="comments" :relationCheckResult="{}" :readOnly="true" />
          </div>
        </ButtonCreatePDF>
      </CheckResultDetail>

      <!-- 特記事項カード、承認ボタン -->
      <div class="d-flex align-center">
        <CardComments
          ref="comments"
          :date="selectedDate"
          :isMonthly="false"
          serviceName="日報"
          @load="comments = $event"
        />
        <v-btn
          v-if="isShowButton"
          id="btn-approve-result"
          class="ml-8 mr-2 mr-sm-4 mt-4 mt-sm-0 mb-2 mb-sm-0"
          color="primary"
          width="160"
          large
          rounded
          :loading="processing"
          :disabled="processing || isDisabledButton"
          @click="approvalList()"
        >
          {{ buttonName }}
        </v-btn>
      </div>
    </v-card>

    <DialogSendError ref="sendErrorDialog" />
    <DialogMessage :dialog="messageDialog" :message="message" @close="messageDialog = false" />
  </v-dialog>
</template>

<script>
import firebase from "../../plugins/firebase";
import { db } from "../../plugins/firebase";
import { logEvent } from "../../plugins/firebase";
import calcDate from "cumin-common/src/mixins/calcDate";
import CheckResultDetail from "../organisms/CheckResultDetail";

export default {
  components: {
    CheckResultDetail,
  },
  mixins: [calcDate],
  data: () => ({
    dialog: false,
    selectedDate: "",
    warningMessage: "",
    buttonName: "",
    isDisabledButton: false,
    item: {},
    comments: [],
    processing: false,
    message: "",
    messageDialog: false,
  }),
  created: function () {
    const user = this.$store.getters.getUser;
    this.isShowButton = user.authority == "approver" || user.authority == "confirmer";
  },
  methods: {
    /**
     * ダイアログを開く
     * @param {Object} selectedItem 選択した行データ
     */
    async openDialog(selectedItem) {
      this.item = selectedItem;
      this.selectedDate = this.formatDate(this.item.date, "YYYY/MM/DD");
      this.isDisabledButton = false;
      this.warningMessage = "";

      const user = this.$store.getters.getUser;
      this.isShowButton = user.authority == "confirmer" || user.authority == "approver";

      if (this.isShowButton) {
        if (user.authority == "confirmer") {
          if (this.item.confirmerName) {
            this.buttonName = "確認済";
            this.isDisabledButton = true;
          } else {
            this.buttonName = "確 認";
          }
        }

        if (user.authority == "approver") {
          if (this.item.approverName) {
            this.buttonName = "承認済";
            this.isDisabledButton = true;
          } else {
            this.buttonName = "承 認";
          }
        }

        // 当日の場合は一つでも未点検がある場合、確認/承認不可
        const businessDate = this.calculateBusinessDate(new Date());
        if (this.selectedDate == businessDate) {
          const uncheckResults = this.item.checkItems.filter(
            (item) => !item.checkResult && item.referenceService == ""
          );
          if (uncheckResults.length > 0) {
            this.isDisabledButton = true;
            this.warningMessage = "当日未点検の項目があるため、確認/承認できません。";
          }
        } else {
          // 日報の点検結果がない場合
          if (!this.item.id) {
            this.isDisabledButton = true;
            this.warningMessage = "日報の点検結果がありません。";
          }
        }
      }

      this.dialog = true;
      this.$nextTick(function () {
        this.$refs.comments.loadComment();
      });
    },

    /**
     * 点検票承認
     */
    async approvalList() {
      // オフライン時の処理
      if (!navigator.onLine) {
        this.$refs.sendErrorDialog.open("offline");
        return;
      }

      this.processing = true;

      const user = this.$store.getters.getUser;
      const updateAt = firebase.firestore.Timestamp.fromDate(new Date());
      const uid = this.item.id;
      const item = {};
      let isSucceeded = false;

      switch (user.authority) {
        // ログインユーザーが確認者の場合
        case "confirmer":
          item.confirmedAt = updateAt;
          item.confirmerName = user.name;
          item.updatedAt = updateAt;
          isSucceeded = await this.updateDB(item, uid);
          if (isSucceeded) this.message = "日報を確認しました。";
          if (!isSucceeded)
            this.message = "日報の確認に失敗しました。\nお手数ですが、もう一度お試しください。";
          logEvent("confirm", { message: this.message });
          break;

        // ログインユーザーが承認者の場合
        case "approver":
          if (!this.item.confirmerName) {
            item.confirmedAt = updateAt;
            item.confirmerName = user.name;
            item.updatedAt = updateAt;
          }
          if (!this.item.approverName) {
            item.approvedAt = updateAt;
            item.approverName = user.name;
            item.updatedAt = updateAt;
          }
          isSucceeded = await this.updateDB(item, uid);
          if (isSucceeded) this.message = "日報を承認しました。";
          if (!isSucceeded)
            this.message = "日報の承認に失敗しました。\nお手数ですが、もう一度お試しください。";
          if (!this.item.confirmerName) logEvent("confirm", { message: this.message });
          if (!this.item.approverName) logEvent("approve", { message: this.message });
          break;
      }
      if (isSucceeded) this.$emit("update", { item, uid });

      this.processing = false;
      this.messageDialog = true;
      this.dialog = false;
    },

    /**
     * DB更新
     * @param {Object} item
     * @param {string} uid
     * @return {Object} 更新結果
     */
    async updateDB(item, uid) {
      return await db
        .collection("reportResults")
        .doc(uid)
        .set(item, {
          merge: true,
        })
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.log("DB登録に失敗しました。：" + error.message);
          logEvent("error_db_write", {
            method_name: "updateDB",
            error_message: error.message,
          });
          return false;
        });
    },
  },
};
</script>
