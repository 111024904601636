<template>
  <div>
    <v-progress-circular
      v-if="isCheck"
      :rotate="-90"
      :size="large ? 100 : 50"
      :width="large ? 8 : 5"
      :value="value"
      :color="color"
    >
      <span :class="large ? 'text-h5' : 'text-body-2'">{{ Math.round(value) }}%</span>
    </v-progress-circular>
    <span v-else class="grey--text" :class="large ? 'text-body-2' : 'text-caption'">未点検</span>
  </div>
</template>

<script>
export default {
  props: {
    isCheck: Boolean,
    value: Number,
    color: String,
    large: Boolean,
  },
};
</script>
