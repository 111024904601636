<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col align-self="center">
        <span class="mr-5 text-subtitle-1 text-sm-h6">衛生管理記録票</span>
        <span class="text-caption text-sm-subtitle-1 text--secondary">{{ businessDate }}</span>
      </v-col>
    </v-row>

    <!-- 点検票 -->
    <v-row class="pb-4" no-gutters>
      <v-col>
        <v-card>
          <v-data-table
            :headers="$vuetify.breakpoint.xs ? xsHeaders : headers"
            :items="items"
            :items-per-page="-1"
            :loading="loading"
            loading-text="読込中"
            no-data-text="データがありません"
            disable-sort
            fixed-header
            hide-default-footer
            :height="getTableHeight"
            :mobile-breakpoint="null"
          >
            <template v-if="$vuetify.breakpoint.smAndUp" v-slot:[`header.checkResult`]="{}">
              <div class="d-flex justify-space-between align-center">
                <span></span>
                <span>点検結果</span>
                <MenuComment :item="commentItem" targetKey="title" />
              </div>
            </template>
            <template v-else v-slot:[`header.checkResult`]="{}">
              <div class="d-flex justify-end align-center">
                <MenuComment :item="commentItem" targetKey="title" />
              </div>
            </template>
            <template v-slot:item="{ item, index }">
              <tr
                v-if="$vuetify.breakpoint.smAndUp"
                :class="getRowColor(isUsingService(item.referenceService), index)"
                @click="selectedRow = index"
              >
                <td>
                  <p
                    :class="item.category == '重点衛生管理' ? 'warning--text' : 'accent--text'"
                    style="font-size: 11px"
                  >
                    <span>{{ item.category }}</span>
                    <span v-if="item.category && item.solution"> : </span>
                    <span>{{ item.solution }}</span>
                  </p>
                  <div class="d-flex">
                    <div style="min-width: 24px">{{ items.indexOf(item) + 1 }}.</div>
                    <template v-if="isUsingService(item.referenceService)">
                      {{ services[item.referenceService].name }}
                    </template>
                    <template v-else>{{ item.questionContent }}</template>
                  </div>
                </td>
                <td>
                  <template v-if="item.sender">
                    <p class="text-caption">
                      {{ formatDate(new Date(item.sentAt), "HH:mm") }} {{ item.checkResult }}
                    </p>
                    <p class="grey--text" style="font-size: 11px">{{ item.sender }}</p>
                  </template>
                </td>
                <td>
                  <template v-if="!isUsingService(item.referenceService)">
                    <ButtonCheckResult
                      :clearable="true"
                      :disabled="isDisabled"
                      :value="results[index]"
                      @input="$set(results, index, $event)"
                    />
                  </template>
                </td>
              </tr>
              <tr
                v-else
                :class="getRowColor(isUsingService(item.referenceService), index)"
                @click="selectedRow = index"
              >
                <td class="py-2" :colspan="headers.length">
                  <p class="grey--text" style="font-size: 11px">
                    <span>{{ item.category }}</span>
                    <span v-if="item.category && item.solution"> : </span>
                    <span>{{ item.solution }}</span>
                  </p>
                  <div class="d-flex">
                    <div style="min-width: 24px">{{ items.indexOf(item) + 1 }}.</div>
                    <template v-if="isUsingService(item.referenceService)">
                      {{ services[item.referenceService].name }}
                    </template>
                    <template v-else>{{ item.questionContent }}</template>
                  </div>
                  <div v-if="!isUsingService(item.referenceService)" class="pt-2 d-flex">
                    <div class="text-caption text--secondary">
                      <template v-if="item.sender">
                        <p>
                          点検時間：{{ formatDate(new Date(item.sentAt), "HH:mm") }}
                          {{ item.checkResult }}
                        </p>
                        <p>{{ item.sender }}</p>
                      </template>
                    </div>
                    <v-spacer></v-spacer>
                    <ButtonCheckResult
                      :clearable="true"
                      :disabled="isDisabled"
                      :value="results[index]"
                      @input="$set(results, index, $event)"
                    />
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- 特記事項・送信ボタン -->
    <div class="d-sm-flex align-center">
      <CardComments
        ref="comments"
        :date="businessDate"
        :isMonthly="false"
        serviceName="日報"
        @load="existsComment = $event.length > 0"
      />
      <ButtonSendResult
        :loading="sending"
        :isNormal="results.every((result) => result != 'NG')"
        :disabled="isDisabledButton"
        :buttonText="buttonText"
        @click="sendCheckResult()"
      />
    </div>

    <DialogSendError ref="sendErrorDialog" />
    <DialogMessage
      :dialog="messageDialog"
      :message="message"
      :messageText="messageText"
      @close="messageDialog = false"
    />
  </v-container>
</template>

<script>
import firebase from "../../plugins/firebase";
import { db } from "../../plugins/firebase";
import { logEvent } from "../../plugins/firebase";
import moment from "moment";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import uploadStorage from "cumin-common/src/mixins/uploadStorage";

export default {
  mixins: [calcDate, dbProcess, uploadStorage],
  data: () => ({
    timerID: null,
    loading: false,
    businessDate: "",
    buttonText: "",
    isDisabled: false,
    sentResultUID: "",
    sentResult: {},
    results: [],
    items: [],
    headers: [
      { text: "点検項目", value: "questionContent" },
      { text: "点検時間", value: "sender", width: 120 },
      { text: "点検結果", value: "checkResult", width: 160 },
    ],
    xsHeaders: [
      { text: "点検項目", value: "questionContent" },
      { text: "点検結果", value: "checkResult", align: "center", width: 160 },
    ],
    services: {
      personalHygiene: { name: "個人衛生点検", icon: "mdi-hand-wash-outline" },
      internalTemperature: { name: "庫内温度点検", icon: "icon-fridge-thermometer" },
      facility: { name: "機械点検", icon: "icon-facility" },
      clean: { name: "清掃点検", icon: "mdi-broom" },
      weightflow: { name: "重量点検", icon: "mdi-scale" },
      inspection: { name: "異物探知", icon: "mdi-magnify-expand" },
      acceptance: { name: "受入管理", icon: "mdi-cart-check" },
    },
    selectedRow: null,
    commentItem: { comment: "", title: "この点検票" },
    existsComment: false,
    sending: false,
    message: "",
    messageText: "",
    messageDialog: false,
  }),
  created: function () {
    this.$emit("created");
    logEvent("app_connect");

    this.businessDate = this.calculateBusinessDate(new Date());
  },
  activated: async function () {
    await this.loadList();
    this.$nextTick(() => this.$refs.comments.loadComment());

    const setDate = () => (this.businessDate = this.calculateBusinessDate(new Date()));
    this.timerID = setInterval(setDate, 60000);
  },
  deactivated: function () {
    clearInterval(this.timerID);
  },
  computed: {
    /**
     * テーブルの高さを取得
     * @return {number} 高さ
     */
    getTableHeight() {
      const bp = this.$vuetify.breakpoint;
      const offset = this.existsComment ? 186 : 60;
      const height = bp.height - (bp.xs ? 212 : 204) - offset;
      return height <= 500 ? 500 : height;
    },

    /**
     * テーブルの行の色を取得
     * @param {Boolean} isUsingService
     * @param {number} index
     * @return {string} クラス名
     */
    getRowColor() {
      return function (isUsingService, index) {
        if (this.results[index] == "NG") return "red lighten-5";
        if (isUsingService) return "grey lighten-3";
        if (this.selectedRow == index) return "tertiary";
        return "transparent";
      };
    },

    /**
     * 点検されていない場合送信ボタンを非活性にする
     * @return {boolean} ボタン非活性かどうか
     */
    isDisabledButton() {
      if (this.sending || this.isDisabled) return true;
      if (this.results.includes("OK")) return false;
      if (this.results.includes("NG")) return false;
      if (this.commentItem.comment) return false;
      if (this.commentItem.imgFileUrl) return false;
      return true;
    },

    /**
     * アプリ利用状況の取得
     * @param {string} service
     */
    isUsingService() {
      return function (service) {
        if (this.services[service]) return this.services[service].isUsing;
      };
    },
  },
  methods: {
    /**
     * DBから点検項目を取得
     */
    async loadList() {
      this.loading = true;
      const shop = this.$store.getters.getShop;
      const date = new Date(this.businessDate + " 00:00:00");
      this.items = [];
      this.results = [];

      // 送信済の点検結果を取得
      const results = await db
        .collection("reportResults")
        .where("shopUID", "==", shop.shopUID)
        .orderBy("registeredAt", "asc")
        .startAt(date)
        .get()
        .catch((error) => {
          logEvent("error_db_read", {
            method_name: "loadList",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      if (results.size > 0) {
        // 点検結果がある場合、結果から内容を取得
        this.sentResultUID = results.docs[0].id;
        this.sentResult = results.docs[0].data();
        this.sentResult.checkItems.forEach((item) => {
          this.items.push(item);
          if (item.checkResult) {
            this.results.push(item.checkResult);
          } else {
            this.results.push("");
          }
          if (item.referenceService) {
            if (this.services[item.referenceService])
              this.services[item.referenceService].isUsing = shop[item.referenceService];
          }
        });
        if (this.sentResult.confirmerName != "") {
          this.buttonText = "確認済";
          this.isDisabled = true;
        } else {
          this.buttonText = "";
        }
      } else {
        // 日報フォーマットをDBから取得する
        const reportUID = await db
          .collection("shopReportQuestionLink")
          .where("shopUID", "==", shop.shopUID)
          .get()
          .then((links) => {
            return links.docs[0].data().reportQuestionUID;
          })
          .catch((error) => {
            logEvent("error_db_read", {
              method_name: "loadList",
              error_message: error.message,
            });
            this.$router.replace({
              name: "SystemError",
              params: { 0: location.pathname, error: error.message },
            });
          });

        // 点検結果が無い場合、質問テーブルから内容を取得
        await db
          .collection("reportQuestions")
          .doc(reportUID)
          .get()
          .then((question) => {
            question.data().checkItems.forEach((item) => {
              this.items.push(item);
              this.results.push("");
              if (item.referenceService) {
                if (this.services[item.referenceService])
                  this.services[item.referenceService].isUsing = shop[item.referenceService];
              }
            });
          })
          .catch((error) => {
            logEvent("error_db_read", {
              method_name: "loadList",
              error_message: error.message,
            });
            this.$router.replace({
              name: "SystemError",
              params: { 0: location.pathname, error: error.message },
            });
          });

        this.buttonText = "";
      }
      this.loading = false;
    },

    /**
     * 点検結果送信
     */
    async sendCheckResult() {
      // 送信するかどうかの判定
      const shouldSend =
        !this.sentResultUID ||
        this.commentItem.comment ||
        this.commentItem.imgFileUrl ||
        this.results.some((r, i) => r != "" && this.sentResult.checkItems[i].checkResult != r);

      if (!shouldSend) {
        this.message = "新規の点検結果が無いため送信できません。";
        this.messageText = "";
        logEvent("send_check_list", { message: this.message });
        this.messageDialog = true;
        return;
      }

      // オフライン時の処理
      if (!navigator.onLine) return this.$refs.sendErrorDialog.open("offline");

      this.sending = true;

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const sendDate = new Date(this.businessDate + " 00:00:00");
      const registeredAt = firebase.firestore.Timestamp.fromDate(sendDate);
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
      const sentAt = moment().format("YYYY/MM/DD HH:mm:ss");

      let sentCount = 0;
      let exsitsAbnormal = false;

      // 送信データ作成
      const resultId = this.sentResultUID || this.createDocId("reportResults");
      const sendData = {
        shopUID: shop.shopUID,
        checkItems: this.results.map((result, i) => {
          const exists = this.sentResultUID && this.sentResult.checkItems[i].checkResult === result;
          if (exists) return this.sentResult.checkItems[i];

          if (result === "NG") exsitsAbnormal = true;
          if (result !== "") sentCount++;
          return {
            managementID: this.items[i].managementID,
            category: this.items[i].category,
            solution: this.items[i].solution,
            referenceService: this.items[i].referenceService ?? "",
            questionContent: this.items[i].questionContent,
            sentAt: result ? sentAt : "",
            sender: result ? user.name : "",
            checkResult: result,
          };
        }),
        lastSentAt: serverTimestamp,
        registeredAt,
        confirmedAt: "",
        confirmerName: "",
        approvedAt: "",
        approverName: "",
        updatedAt: serverTimestamp,
      };
      if (!this.sentResultUID) sendData.createdAt = serverTimestamp;

      // ドキュメント情報作成
      const writeItems = [
        {
          method: this.sentResultUID ? "update" : "set",
          collection: "reportResults",
          docId: resultId,
          data: sendData,
        },
      ];

      // 特記事項がある場合
      if (this.commentItem.comment || this.commentItem.imgFileUrl) {
        const commentId = this.createDocId("comments");
        const sendCommentData = {
          name: user.name,
          registrantUID: user.userUID,
          shopUID: shop.shopUID,
          position: user.position,
          content: this.commentItem.comment,
          serviceName: "日報",
          relationCheckResult: {
            uid: resultId,
            title: moment().format("YYYY/MM/DD"),
            isNormal: !exsitsAbnormal,
            sentAt: moment().format("MM/DD HH:mm"),
          },
          registeredAt,
          createdAt: serverTimestamp,
          updatedAt: serverTimestamp,
        };

        // 画像ありの場合、画像をアップロードする
        if (this.commentItem.compressedFile) {
          const commentDate = this.businessDate.replace(/\//g, "-");
          const path = `commentsFile/${shop.shopUID}/${commentDate}/${commentId}.jpg`;

          const uploadResult = await this.uploadFile({
            path,
            file: this.commentItem.compressedFile,
          });

          // 送信失敗の場合
          if (uploadResult.status == "error") {
            const type =
              uploadResult.code === "storage/retry-limit-exceeded" ? "unstable" : "unexpected";
            this.$refs.sendErrorDialog.open(type);
            this.sending = false;
            return;
          }

          sendCommentData.imgFileURL = uploadResult.url;
        }

        writeItems.push({
          method: "set",
          collection: "comments",
          docId: commentId,
          data: sendCommentData,
        });
      }

      // DB登録
      const result = await this.writeTransaction(writeItems);

      // 送信失敗の場合
      if (result.status !== "success") {
        const type = result.message == "Connection failed." ? "unstable" : "unexpected";
        this.$refs.sendErrorDialog.open(type);
        this.logEvent("send_check_list", { message: "点検票の送信に失敗しました。" });
        this.sending = false;
        return;
      }

      // 特記事項リストの更新
      const isSentComment = this.commentItem.comment || this.commentItem.imgFileUrl;
      if (isSentComment) await this.$refs.comments.loadComment();

      // 点検票の更新
      await this.loadList();
      this.commentItem = { comment: "", title: "この点検票" };

      this.message = "送信しました。";
      this.messageText = [
        `点検数：${sentCount}`,
        `特記事項：${isSentComment ? "あり" : "なし"}`,
      ].join("　");
      this.messageDialog = true;
      this.logEvent("send_check_list", { message: this.message + this.messageText });

      this.sending = false;

      // NGメールの送信
      if (exsitsAbnormal) await this.sendNgMail();
    },

    /**
     * 点検結果にNGがあった場合、メール送信
     */
    async sendNgMail() {
      const shop = this.$store.getters.getShop;
      let environment = "";
      if (process.env.NODE_ENV == "test") environment = "[DEV]";
      if (process.env.NODE_ENV == "development") environment = "[DEV]";
      if (process.env.NODE_ENV == "staging") environment = "[STG]";

      const functions = await firebase.app().functions("asia-northeast1");
      const sendMail = functions.httpsCallable("sendMail");

      const subject =
        shop.shopUID == this.$route.params.shopUID
          ? `${environment}[ハレコード] 日報結果NGのお知らせ`
          : `${environment}[ハレコード][${shop.selectShopName}] 日報結果NGのお知らせ`;

      const mailResult = await sendMail({
        position: this.$store.state.user.position,
        shopUID: this.$store.state.shop.shopUID,
        subject: subject,
        text:
          "各位\n\n" +
          "日報の点検結果にNGがありましたのでお知らせいたします。\n\n" +
          "【店舗名】\n" +
          "　" +
          `${shop.selectShopName}\n\n\n` +
          "以下のURLからログインして内容のご確認をお願いいたします。\n" +
          `${window.location.origin}/${shop.shopUID}/check-summary\n\n` +
          "※このメールは送信専用のため、ご返信いただけません。\n\n\n" +
          "－－－－－－－－－－－－－－－－－－－－－－－－－－－－－－\n" +
          "ハレコードは株式会社ウエノフードテクノの商標です。\n",
      });

      if (mailResult.data.status == "error") {
        logEvent("error_function", {
          method_name: mailResult.data.method,
          error_message: mailResult.data.error.message,
        });
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table .v-data-table__wrapper table .v-data-table-header tr th {
  height: 48px !important;
  background-color: #f8f8f8 !important;
  padding: 0 8px;
}

::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  height: 48px !important;
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper table thead tr th:first-child,
::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 16px !important;
}

::v-deep .v-data-table__wrapper table thead tr th:last-child,
::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 16px !important;
}

p {
  margin: 0;
}
</style>
