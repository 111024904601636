import { db } from "../plugins/firebase";
import { logEvent } from "../plugins/firebase";
import moment from "moment";

const services = [
  { name: "personalHygiene", collection: "questionAnswers", icon: "mdi-hand-wash-outline" },
  {
    name: "internalTemperature",
    collection: "fridgeCheckResults",
    icon: "icon-fridge-thermometer",
  },
  { name: "facility", collection: "facilityCheckResults", icon: "icon-facility" },
  {
    name: "criticalControl",
    collection: "criticalControlResults",
    icon: "mdi-text-box-check-outline",
  },
  { name: "clean", collection: "cleaningCheckResults", icon: "mdi-broom" },
  { name: "weightflow", collection: "weightCheckResults", icon: "mdi-scale" },
  {
    name: "inspection",
    collection: "metalDetectorCheckResults",
    icon: "mdi-magnify-expand",
  },
  {
    name: "acceptance",
    collection: "acceptanceCheckResults",
    icon: "mdi-cart-check",
  },
];

export default {
  methods: {
    /**
     * 日報、個別点検結果を集計
     * @param {date} startAt 抽出開始日時
     * @param {date} endAt 抽出終了日時
     * @return {array} 集計後のリスト
     */
    async createSummary(startAt, endAt) {
      const items = [];
      const shop = this.$store.getters.getShop;

      // 日報フォーマットをDBから取得する
      const reportUID = await db
        .collection("shopReportQuestionLink")
        .where("shopUID", "==", shop.shopUID)
        .get()
        .then((links) => {
          return links.docs[0].data().reportQuestionUID;
        })
        .catch((error) => {
          logEvent("error_db_read", {
            method_name: "createSummary",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      // 日報の点検項目をDBから取得
      const question = await db
        .collection("reportQuestions")
        .doc(reportUID)
        .get()
        .catch((error) => {
          logEvent("error_db_read", {
            method_name: "createSummary",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      // 日報の点検結果をDBから取得
      const dailyReports = await db
        .collection("reportResults")
        .where("shopUID", "==", shop.shopUID)
        .orderBy("registeredAt", "asc")
        .startAt(startAt)
        .endAt(endAt)
        .get()
        .then(async (reports) => {
          const arrayReports = [];
          await Promise.all(
            reports.docs.map(async (report) => {
              arrayReports.push(report.data());
              arrayReports[arrayReports.length - 1].id = report.id;
            })
          );
          return arrayReports;
        })
        .catch((error) => {
          console.log("点検結果の取得に失敗しました。：" + error.message);
          logEvent("error_db_read", {
            method_name: "createSummary",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      // 個別サービスの点検結果をDBから取得
      await Promise.all(
        services.map(async (service) => {
          service.results = await db
            .collection(service.collection)
            .where("shopUID", "==", shop.shopUID)
            .orderBy("registeredAt", "asc")
            .startAt(startAt)
            .endAt(endAt)
            .get()
            .catch((error) => {
              console.log(service.name + "：" + error.message);
              logEvent("error_db_read", {
                method_name: "createSummary",
                error_message: error.message,
              });
              this.$router.replace({
                name: "SystemError",
                params: { 0: location.pathname },
              });
            });
        })
      );

      // 特記事項をDBから取得
      const reportComments = await db
        .collection("comments")
        .where("shopUID", "==", shop.shopUID)
        .orderBy("registeredAt", "asc")
        .startAt(startAt)
        .endAt(endAt)
        .get()
        .then(async (reports) => {
          const arrayComments = [];
          await Promise.all(
            reports.docs.map(async (report) => {
              arrayComments.push(report.data());
              arrayComments[arrayComments.length - 1].id = report.id;
            })
          );
          return arrayComments;
        })
        .catch((error) => {
          console.log("特記事項の取得に失敗しました。：" + error.message);
          logEvent("error_db_read", {
            method_name: "createSummary",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      // 日数分ループ
      const start = moment(startAt);
      const end = moment(endAt);
      for (let target = start; target.unix() <= end.unix(); target.add(1, "days")) {
        // 対象日の日報の点検結果を抽出
        let item = dailyReports.filter((result) => {
          return result.registeredAt.seconds == target.unix();
        })[0];

        // 対象日の日報の点検結果がある場合
        if (item) {
          item.serviceResult = {};
          item.unapprovedServices = [];

          // 個別サービスの点検結果を集計する
          services.forEach(async (service) => {
            item.serviceResult[service.name] = {
              okCount: 0,
              ngCount: 0,
              unapprovedCount: 0,
            };

            // 対象サービスの対象日の点検結果を抽出
            const filterDocs = service.results.docs.filter((doc) => {
              return doc.data().registeredAt.seconds == target.unix();
            });

            // 対象サービスの対象日の点検結果がある場合、集計
            if (filterDocs.length > 0) {
              item.serviceResult[service.name] = {
                okCount: filterDocs.filter((doc) => doc.data().isNormalForReport == true).length,
                ngCount: filterDocs.filter((doc) => doc.data().isNormalForReport == false).length,
                unapprovedCount: filterDocs.filter((doc) => doc.data().approverName == "").length,
              };
              // let isNormalResult = filterDocs.every((doc) => doc.data().isNormalForReport == true);
              let isApproved = filterDocs.every((doc) => doc.data().approverName != "");

              // const index = item.checkItems.findIndex(
              //   (item) => item.referenceService == service.name
              // );
              // if (index > -1) {
              //   if (isNormalResult) item.checkItems[index].checkResult = "OK";
              //   if (!isNormalResult) item.checkItems[index].checkResult = "NG";
              // }
              if (isApproved) item.unapprovedServices.push("");
              if (!isApproved) item.unapprovedServices.push(service.icon);
            }
          });

          // 日報未承認の場合、未承認サービスに日報アイコンを追加
          if (item.approverName == "") item.unapprovedServices.splice(3, 0, "mdi-calendar-today");

          // 日報の点検結果を集計
          item.okCount = item.checkItems.filter((item) => item.checkResult == "OK").length;
          item.ngCount = item.checkItems.filter((item) => item.checkResult == "NG").length;
          item.uncheckedCount = item.checkItems.filter(
            (item) => item.checkResult == "" && item.referenceService == ""
          ).length;
        } else {
          // 対象日の日報の点検結果が無い場合
          item = {
            serviceResult: {},
            unapprovedServices: [],
            checkItems: JSON.parse(JSON.stringify(question.data().checkItems)),
            approvedAt: "点検なし",
            confirmedAt: "点検なし",
          };

          // 個別サービスの点検結果を集計する
          services.forEach(async (service) => {
            item.serviceResult[service.name] = {
              okCount: 0,
              ngCount: 0,
              unapprovedCount: 0,
            };

            // 対象サービスの対象日の点検結果を抽出
            const filterDocs = service.results.docs.filter((doc) => {
              return doc.data().registeredAt.seconds == target.unix();
            });

            // 未承認点検があるか
            if (filterDocs.length > 0) {
              item.serviceResult[service.name] = {
                okCount: filterDocs.filter((doc) => doc.data().isNormalForReport == true).length,
                ngCount: filterDocs.filter((doc) => doc.data().isNormalForReport == false).length,
                unapprovedCount: filterDocs.filter((doc) => doc.data().approverName == "").length,
              };
              let isNormalResult = filterDocs.every((doc) => doc.data().isNormalForReport == true);
              let isApproved = filterDocs.every((doc) => doc.data().approverName != "");

              const index = item.checkItems.findIndex(
                (item) => item.referenceService == service.name
              );
              if (index > -1) {
                if (isNormalResult) item.checkItems[index].checkResult = "OK";
                if (!isNormalResult) item.checkItems[index].checkResult = "NG";
              }
              if (!isApproved) item.unapprovedServices.push(service.icon);
            }

            item.okCount = item.checkItems.filter((item) => item.checkResult == "OK").length;
            item.ngCount = item.checkItems.filter((item) => item.checkResult == "NG").length;
            item.uncheckedCount = item.checkItems.filter(
              (item) => item.checkResult == undefined && item.referenceService == ""
            ).length;
          });
        }
        item.date = target.unix() * 1000;

        // 対象日の日報の特記事項を抽出
        let comments = reportComments.filter((comment) => {
          return comment.registeredAt.seconds == target.unix();
        });
        if (comments.length > 0) item.comments = [...comments];
        items.push(item);
      }
      return items;
    },
  },
};
