<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="-1"
    :loading="loading"
    loading-text="読込中"
    no-data-text="点検結果はありません"
    fixed-header
    hide-default-footer
    :mobile-breakpoint="null"
    :height="height"
    dense
  >
    <template #item="{ item, index }">
      <tr :class="selectedResult == item.id ? 'tertiary' : ''">
        <template v-for="h in headers">
          <td v-if="h.value === 'registeredAt'" :key="`${h.value}_${index}`">
            {{ formatDate(item.registeredAt, "M/D") }}
          </td>

          <td v-else-if="h.value === 'sentAt'" :key="`${h.value}_${index}`">
            {{ formatDate(item.sentAt, "MM/DD HH:mm") }}
          </td>

          <td v-else-if="h.value === 'isNormal'" :key="`${h.value}_${index}`">
            <span v-if="item.isNormal">OK</span>
            <span v-else class="red--text font-weight-bold">NG</span>
          </td>

          <td v-else-if="h.value === 'isConfirmed'" :key="`${h.value}_${index}`">
            <span>{{ item.confirmerName ? "〇" : "" }}</span>
          </td>
          <td v-else-if="h.value === 'isApproved'" :key="`${h.value}_${index}`">
            <span>{{ item.approverName ? "〇" : "" }}</span>
          </td>

          <td v-else-if="h.value === 'action'" :key="`${h.value}_${index}`" class="text-end">
            <v-btn
              v-if="item.comment.relationCheckResult.uid"
              icon
              @click="$emit('clickComment', item.comment.relationCheckResult.uid)"
            >
              <v-icon v-if="item.comment">mdi-comment-processing-outline</v-icon>
            </v-btn>
            <v-btn icon @click="$emit('clickTransition', item.date)">
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
          </td>

          <td v-else :key="`${h.value}_${index}`">{{ item[h.value] }}</td>
        </template>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";

export default {
  props: {
    appName: String,
    items: Array,
    selectedResult: String,
    loading: Boolean,
    height: Number,
  },
  mixins: [calcDate],
  data: () => ({
    commonHeaders: [
      { text: "点検日", value: "registeredAt", width: 80 },
      { text: "送信日時", value: "sentAt", width: 100 },
      { text: "結果", value: "isNormal", width: 60 },
      { text: "確認", value: "isConfirmed", width: 60 },
      { text: "承認", value: "isApproved", width: 60 },
      { text: "", value: "action", sortable: false, width: 96 },
    ],
    appHeader: {
      personalHygiene: { text: "送信者", value: "sender" },
      internalTemperature: { text: "冷蔵庫名", value: "fridgeName" },
      facility: { text: "機械名", value: "facilityName" },
      clean: { text: "清掃場所", value: "place" },
      weightflow: { text: "製品名", value: "productName" },
      inspection: { text: "製品名", value: "productName" },
      acceptance: { text: "業者名", value: "supplierName" },
      corethermo: { text: "帳票名", value: "sheetName" },
      criticalControl: { text: "送信者", value: "sender" },
    },
  }),
  computed: {
    headers() {
      return this.commonHeaders.toSpliced(2, 0, this.appHeader[this.appName]);
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table__wrapper table thead tr th {
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper table tbody tr td {
  height: 36px !important;
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper table thead tr th:first-child,
::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 16px !important;
}

::v-deep .v-data-table__wrapper table thead tr th:last-child,
::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 16px !important;
}
</style>
