<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row no-gutters>
      <v-col class="pa-4" cols="5" sm="4" align-self="center">
        <span class="text-subtitle-1 text-sm-h6">アプリ承認状況</span>
      </v-col>
      <v-col class="px-4 pt-2 pb-3" cols="7" sm="4" align-self="center">
        <MenuMonthPicker :month="month" @update="updateMonth" />
      </v-col>
      <v-col class="px-4 py-2 d-flex justify-end align-center">
        <v-btn
          class="primary--text"
          color="white"
          v-if="mode == 'summary'"
          @click="changeMode('holiday')"
        >
          休日設定
        </v-btn>
        <div v-else>
          <v-btn
            class="mr-1 px-0 primary--text text-caption"
            width="80"
            :loading="processing"
            :disabled="processing"
            @click="changeMode('summary')"
            style="z-index: 6"
          >
            キャンセル
          </v-btn>
          <v-btn
            class="ml-1 px-0 primary--text"
            width="80"
            :loading="processing"
            :disabled="
              processing ||
              JSON.stringify(monthHolidays.slice().sort()) ==
                JSON.stringify(editedHolidays.slice().sort())
            "
            @click="saveHoliday()"
            style="z-index: 6"
          >
            保存
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- カレンダー -->
    <v-overlay :value="mode == 'holiday'" z-index="3"></v-overlay>
    <v-row no-gutters>
      <v-sheet :height="getSheetHeight" width="100%" rounded="lg" style="position: relative">
        <v-sheet
          class="progress d-flex justify-center align-center"
          v-if="loading"
          height="100%"
          width="100%"
        >
          <div class="d-flex flex-column align-center" style="width: 200px">
            <span class="text-subtitle-1 text-sm-h6">点検票集計中…</span>
            <v-progress-linear indeterminate color="primary" height="5" />
          </div>
        </v-sheet>
        <v-calendar
          ref="calendar"
          v-model="focus"
          type="month"
          locale="ja-jp"
          :day-format="(date) => date.day"
          @change="loadResult"
          @click:day="mode == 'summary' ? openDetail($event) : editHoliday($event)"
        >
          <template #day="{ date, day, weekday, outside }">
            <v-sheet width="100%" height="100%" :color="getDaySheetColor(date, outside)">
              <v-row no-gutters align="center">
                <v-btn
                  class="transparent"
                  :class="getDayLabelColor(date, weekday)"
                  fab
                  width="28"
                  height="28"
                  depressed
                >
                  <span>{{ day }}</span>
                </v-btn>
                <template v-if="dayData[date]">
                  <v-icon class="mx-1" v-if="dayData[date].comments.length > 0" small>
                    mdi-comment-processing-outline
                  </v-icon>
                  <v-chip
                    class="px-2 mr-1 red--text"
                    v-if="dayData[date].abnormalApps.length > 0"
                    color="red lighten-5"
                    :small="$vuetify.breakpoint.smAndUp"
                    :x-small="$vuetify.breakpoint.xs"
                  >
                    NG
                  </v-chip>
                  <v-spacer></v-spacer>
                  <v-chip
                    class="px-2 mr-1"
                    v-if="dayData[date].isHoliday"
                    :small="$vuetify.breakpoint.smAndUp"
                    :x-small="$vuetify.breakpoint.xs"
                  >
                    休
                  </v-chip>
                </template>
              </v-row>
              <v-row class="pa-sm-1" :ref="date" v-if="dayData[date]" no-gutters>
                <v-chip
                  class="px-1 primary--text"
                  v-if="$vuetify.breakpoint.xs && dayData[date].unapprovedApps.length > 0"
                  color="tertiary"
                  x-small
                >
                  未承認
                </v-chip>
                <template v-if="$vuetify.breakpoint.smAndUp && dayData[date].unapprovedApps">
                  <v-icon
                    v-for="app in dayData[date].unapprovedApps"
                    :key="app.key"
                    color="primary"
                    style="width: 26px"
                  >
                    {{ app.icon }}
                  </v-icon>
                </template>
              </v-row>
            </v-sheet>
          </template>
        </v-calendar>
        <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement">
          <v-card
            class="pa-2"
            v-if="selectedItem.date"
            color="#f5f5f5"
            min-width="300"
            max-width="500"
          >
            <v-card-title class="pa-3 pb-0">
              {{ selectedItem.date }}
              <v-chip class="ml-1 px-2 primary--text" v-if="selectedItem.isHoliday" small>
                休
              </v-chip>
              <v-spacer></v-spacer>
              <v-btn class="mr-n2" icon @click="selectedOpen = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-0 px-2 pb-3 text-caption">
              <div class="py-2">
                <template v-if="selectedItem.unapprovedApps.length > 0">
                  <span class="px-1">未承認の点検結果があります</span>
                  <div class="pb-1">
                    <v-btn
                      class="px-2"
                      v-for="app in selectedItem.unapprovedApps"
                      :key="app.name"
                      color="grey lighten-2"
                      small
                      rounded
                      depressed
                      @click="transitionPage(app, selectedItem.date)"
                    >
                      <v-icon size="18">{{ app.icon }}</v-icon>
                      <span class="mt-1">{{ app.appName }}</span>
                    </v-btn>
                  </div>
                </template>
                <span class="px-1" v-else>未承認の点検結果はありません</span>
              </div>
              <v-divider></v-divider>
              <div class="py-2">
                <template v-if="selectedItem.abnormalApps.length > 0">
                  <span class="px-1">NGの点検結果があります</span>
                  <div class="pb-1">
                    <v-btn
                      class="px-2"
                      v-for="app in selectedItem.abnormalApps"
                      :key="app.name"
                      color="grey lighten-2"
                      small
                      rounded
                      depressed
                      @click="transitionPage(app, selectedItem.date)"
                    >
                      <v-icon size="18">{{ app.icon }}</v-icon>
                      <span class="mt-1">{{ app.appName }}</span>
                    </v-btn>
                  </div>
                </template>
                <span class="px-1" v-else>NGの点検結果はありません</span>
              </div>
              <v-divider></v-divider>
              <div class="py-2">
                <template v-if="selectedItem.comments.length > 0">
                  <span class="px-1">特記事項があります</span>
                  <v-card class="pa-1" flat>
                    <v-list class="pa-0" dense>
                      <v-list-item
                        class="py-1 px-1 d-flex align-start"
                        two-line
                        v-for="(comment, i) in selectedItem.comments"
                        :key="i"
                        style="min-height: auto"
                      >
                        <v-list-item-content class="pa-0 text-body-2">
                          <v-list-item-subtitle class="text-caption font-weight-light d-flex">
                            <span class="mr-2">{{ formatDate(comment.createdAt) }}</span>
                            <span class="mr-2">{{ comment.name }}</span>
                            <span class="mr-2">＃{{ comment.serviceName }}</span>
                          </v-list-item-subtitle>
                          <span class="mt-n1">{{ comment.content }}</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </template>
                <span class="px-1" v-else>特記事項はありません</span>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-row>

    <DialogSendError ref="sendErrorDialog" />
    <DialogMessage :dialog="messageDialog" :message="message" @close="messageDialog = false" />
  </v-container>
</template>

<script>
import moment from "moment";
import { db } from "../../plugins/firebase";
import { logEvent } from "../../plugins/firebase";
import firebase from "../../plugins/firebase";
import dbProcess from "cumin-common/src/mixins/dbProcess";

export default {
  mixins: [dbProcess],
  data: () => ({
    loading: false,
    mode: "summary",
    focus: "",
    apps: [],
    month: "",
    dayData: {},
    monthHolidays: [],
    editedHolidays: [],
    holidaysUID: "",
    selectedElement: null,
    selectedItem: {},
    selectedOpen: false,
    processing: false,
    message: "",
    messageDialog: false,
  }),
  created: function () {
    this.$emit("created");
    logEvent("app_connect");
    this.month = moment().format("YYYY/MM");
  },
  computed: {
    /**
     * テーブルの高さを取得
     * @return {number} 高さ
     */
    getSheetHeight() {
      const height = this.$vuetify.breakpoint.height;
      const size = this.$vuetify.breakpoint.name;
      const offset = size == "xs" ? 264 : 192;
      return height <= 500 ? 500 : height - offset;
    },

    /**
     * 日付部分のクラスを取得
     * @param {string} date YYYY-MM-DD
     * @param {string} weekday 曜日番号
     * @return {string} クラス名
     */
    getDayLabelColor: function () {
      return function (date, weekday) {
        const today = moment().format("YYYY-MM-DD");
        if (today == date && weekday == 0) return "red white--text";
        if (today == date) return "primary white--text";
        if (weekday == 0) return "red--text";
      };
    },

    /**
     * 日付部分のクラスを取得
     * @param {string} date YYYY-MM-DD
     * @param {boolean} outside 選択月からはずれているか
     * @return {string} クラス名
     */
    getDaySheetColor: function () {
      return function (date, outside) {
        if (this.editedHolidays.includes(date) && this.mode == "holiday") return "tertiary";
        if (outside) return "grey lighten-4";
      };
    },

    /**
     * date表記をYYYT/MM/DD HH:mm:ss形式の文字列に変換
     * @param {date} value
     * @return {string} 変換後の文字列
     */
    formatDate: function () {
      return function (value) {
        if (!value) return;
        return moment(value.seconds * 1000).format("YYYY/MM/DD HH:mm:ss");
      };
    },
  },
  methods: {
    /**
     * DBから点検結果一覧を取得
     * @param {object} start
     * @param {object} end
     */
    async loadResult({ start, end }) {
      this.loading = true;
      const shop = this.$store.getters.getShop;

      // カレンダーの表示開始日、終了日を算出
      const startDate = new Date(`${start.date.replaceAll("-", "/")} 00:00:00`);
      const endDate = new Date(`${end.date.replaceAll("-", "/")} 23:59:59`);
      this.startAt = new Date(moment(startDate).add(-startDate.getDay(), "days"));
      this.endAt = new Date(moment(endDate).add(6 - endDate.getDay(), "days"));

      // アプリ情報の取得
      this.apps = await this.getQueryDoc({
        collection: "appMenu",
        where: [
          { fieldPath: "category", opStr: "==", value: "haccp" },
          { fieldPath: "shouldSummarize", opStr: "==", value: true },
        ],
        order: [{ fieldPath: "order", directionStr: "asc" }],
      });

      // アプリ別に点検結果を取得
      for (const app of this.apps) {
        this.$set(app, "results", []);
        for (const table of app.resultTables) {
          const results = await this.getQueryDoc({
            collection: table,
            where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
            order: [{ fieldPath: "registeredAt", directionStr: "asc" }],
            startAt: this.startAt,
            endAt: this.endAt,
          });

          for (const result of results) {
            app.results.push({
              date: moment(result.registeredAt.seconds * 1000).format("YYYY-MM-DD"),
              isNormal:
                app.appName == "日報"
                  ? !result.checkItems.some((e) => e.checkResult == "NG")
                  : result.isNormalForReport,
              isConfirmed: result.confirmerName != "",
              isApproved: result.approverName != "",
            });
          }
        }
      }

      // 特記事項情報の取得
      const comments = await this.getQueryDoc({
        collection: "comments",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "registeredAt", directionStr: "asc" }],
        startAt: this.startAt,
        endAt: this.endAt,
      });

      // 休日情報を前後合わせて3か月分取得
      const months = [
        moment(new Date(this.month)).add(-1, "month").format("YYYY/MM"),
        this.month,
        moment(new Date(this.month)).add(1, "month").format("YYYY/MM"),
      ];
      const holidays = [];
      this.holidaysUID = "";
      for (const month of months) {
        // 休日情報をDBから取得
        const docholidays = await this.getQueryDoc({
          collection: "shopHolidays",
          where: [
            { fieldPath: "shopUID", opStr: "==", value: shop.shopUID },
            { fieldPath: "registeredYM", opStr: "==", value: month },
          ],
        });
        if (docholidays.length == 0) continue;

        // 選択月の休日uidを取得
        if (month == this.month) this.holidaysUID = docholidays[0].id;

        // 当月の休日配列の取得、表示日が休日であるかの判定
        docholidays[0].holidays.forEach((holiday, i) => {
          const day = ("00" + (i + 1)).slice(-2);
          if (holiday.isHoliday) holidays.push(`${month.replaceAll("/", "-")}-${day}`);
          if (holiday.isHoliday && month == this.month) {
            this.monthHolidays.push(`${month.replaceAll("/", "-")}-${day}`);
          }
        });
      }

      // 日ごとの情報を集計
      this.dayData = {};
      for (
        let day = moment(this.startAt);
        day.unix() <= moment(this.endAt).unix();
        day.add(1, "days")
      ) {
        const date = moment(day).format("YYYY-MM-DD");
        this.dayData[date] = {
          isHoliday: holidays.includes(date),
          abnormalApps: this.apps.filter((app) =>
            app.results.some((e) => e.date == date && !e.isNormal)
          ),
          unconfirmedApps: this.apps.filter((app) =>
            app.results.some((e) => e.date == date && !e.isConfirmed)
          ),
          unapprovedApps: this.apps.filter((app) =>
            app.results.some((e) => e.date == date && !e.isApproved)
          ),
          comments: comments.filter(
            (e) => moment(e.registeredAt.seconds * 1000).format("YYYY-MM-DD") == date
          ),
        };
      }

      this.loading = false;
    },

    /**
     * 月変更があったらカレンダー更新
     * @param {string} month YYYY/MM
     */
    updateMonth(month) {
      const currentMonth = moment(new Date(`${this.month}/01`));
      const selectedMonth = moment(new Date(`${month}/01`));
      this.$refs.calendar.move(selectedMonth.diff(currentMonth, "month"));
      this.month = month;
    },

    /**
     * 詳細情報をメニュー表示
     * @param {string} date クリックした日付(YYYY-MM-DD)
     */
    openDetail({ date }) {
      const open = () => {
        this.selectedElement = this.$refs[date];
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
        this.selectedItem = { date, ...this.dayData[date] };
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
    },

    /**
     * 休日設定モードと承認状況確認モードの切り替え
     * @param {string} mode 予約バーの要素
     */
    changeMode(mode) {
      this.mode = mode;
      this.editedHolidays = this.monthHolidays.slice();
    },

    /**
     * 休日設定モード時、日付部分をクリックすると日付編集される
     * @param {string} date クリックした日付(YYYY-MM-DD)
     */
    editHoliday({ date }) {
      const exists = this.editedHolidays.includes(date);
      if (exists) this.editedHolidays.splice(this.editedHolidays.indexOf(date), 1);
      if (!exists) this.editedHolidays.push(date);
    },

    /**
     * 休日設定をDBに保存
     */
    async saveHoliday() {
      // オフライン時の処理
      if (!navigator.onLine) {
        this.$refs.sendErrorDialog.open("offline");
        return;
      }

      this.processing = true;
      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;

      // DB登録用に配列作成
      const endDay = moment(new Date(`${this.month}/01`)).daysInMonth();
      const holidays = new Array(endDay).fill().map((_, i) => {
        return {
          day: i + 1,
          isHoliday: this.editedHolidays.includes(
            `${this.month.replaceAll("/", "-")}-${("00" + (i + 1)).slice(-2)}`
          ),
        };
      });

      const storeItem = {
        shopUID: shop.shopUID,
        registeredYM: this.month,
        holidays,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updaterName: user.name,
      };

      // 新規登録の場合
      if (!this.holidaysUID) {
        storeItem.createdAt = firebase.firestore.FieldValue.serverTimestamp();
        this.holidaysUID = db.collection("shopHolidays").doc().id;
      }

      const result = await this.setDoc("shopHolidays", this.holidaysUID, storeItem);
      const isSuccess = result.status == "success";
      this.message = isSuccess
        ? "休日を更新しました。"
        : "休日の更新に失敗しました。\nお手数ですが、もう一度お試しください。";

      if (isSuccess) {
        this.monthHolidays = this.editedHolidays.slice();
        for (let day = 1; day <= endDay; day++) {
          const targetDay = `${this.month.replaceAll("/", "-")}-${("00" + day).slice(-2)}`;
          this.dayData[targetDay].isHoliday = this.monthHolidays.includes(targetDay);
        }
      }
      this.messageDialog = true;
      this.processing = false;
    },

    /**
     * 各アプリの承認画面に遷移
     * @param {object} app アプリ情報
     * @param {string} date 選択した日付(YYYY-MM-DD)
     */
    transitionPage(app, date) {
      location.href = `${app.origin}/${this.$route.params.shopUID}/${
        app.summaryPath
      }?date=${date.replaceAll("-", "/")}`;
    },
  },
};
</script>

<style scoped>
.progress {
  position: absolute;
  z-index: 10;
  opacity: 0.7;
}

::v-deep .v-calendar-weekly__head-weekday {
  background-color: white !important;
  padding: 0px 9px;
  text-align: start;
  border-bottom: #e0e0e0 1px solid;
  margin-right: 0 !important;
  z-index: 4;
}

::v-deep .v-calendar-weekly__head-weekday:nth-of-type(1) {
  color: red !important;
}

::v-deep .v-calendar-weekly__day {
  margin-right: 0 !important;
}

::v-deep .v-calendar-weekly__day-label {
  display: none;
}

::v-deep .v-calendar-weekly__day:not(.v-outside) {
  z-index: 4;
}
</style>
