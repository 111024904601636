<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col cols="4" align-self="center">
        <span class="text-subtitle-1 text-sm-h6">点検結果一覧</span>
      </v-col>
      <v-col cols="5" sm="4" align-self="center">
        <MenuMonthPicker :month="month" @update="updateMonth" />
      </v-col>
    </v-row>

    <!-- 点検結果一覧 -->
    <v-row no-gutters>
      <v-col>
        <v-card>
          <v-sheet class="px-4 pt-4 pb-2 d-flex flex-column flex-sm-row" color="#f8f8f8">
            <span class="text-caption primary--text">
              点検日の行をタップして確認/承認処理を行ってください。
            </span>
            <v-spacer></v-spacer>
            <div class="pt-2 pt-sm-0 d-flex justify-end">
              <v-checkbox v-model="filter.isNG" hide-details :ripple="false">
                <template v-slot:label>
                  <span class="text-caption text--primary">点検NG</span>
                </template>
              </v-checkbox>
              <v-checkbox v-model="filter.isUnchecked" hide-details :ripple="false">
                <template v-slot:label>
                  <span class="text-caption text--primary">未点検</span>
                </template>
              </v-checkbox>
              <v-checkbox v-model="filter.isUnapproved" hide-details :ripple="false">
                <template v-slot:label>
                  <span class="text-caption text--primary">未承認</span>
                </template>
              </v-checkbox>
            </div>
          </v-sheet>
          <v-data-table
            :class="$vuetify.breakpoint.xs ? 'table--xs' : ''"
            :headers="customHeaders"
            :items="items"
            :items-per-page="-1"
            :loading="loading"
            sort-by="date"
            :sort-desc="true"
            loading-text="読込中"
            no-data-text="データがありません。"
            no-results-text="該当するデータが見つかりません。"
            fixed-header
            hide-default-footer
            :height="tableHeight"
            :mobile-breakpoint="null"
          >
            <template v-slot:[`header.lastSentAt`]="{}">
              <div style="min-width: 72px">最終点検日時</div>
            </template>
            <template v-slot:item="{ item, index }">
              <tr
                id="tr-result"
                @click="
                  selectedRow = index;
                  $refs.checkResultDialog.openDialog(item);
                "
                :class="selectedRow == index ? 'tertiary' : 'transparent'"
              >
                <td>
                  {{ formatDate(item.date, "MM/DD ") }}
                  <span :class="dayColor(item.date)">{{ formatDate(item.date, "ddd") }}</span>
                </td>
                <td style="min-width: 72px">{{ formatDate(item.lastSentAt, "MM/DD HH:mm") }}</td>
                <td class="text-center">{{ item.okCount }}</td>
                <td class="text-center">
                  <span :class="item.ngCount > 0 ? 'red--text font-weight-bold' : ''">
                    {{ item.ngCount }}
                  </span>
                </td>
                <td class="text-center">
                  <span :class="item.uncheckedCount > 0 ? 'font-weight-bold' : ''">
                    {{ item.uncheckedCount }}
                  </span>
                </td>
                <td>
                  <span v-if="item.confirmedAt == '点検なし'" class="text--secondary">
                    点検なし
                  </span>
                  <template v-else-if="$vuetify.breakpoint.smAndUp">
                    <p class="text-caption">{{ formatDate(item.confirmedAt, "MM/DD HH:mm") }}</p>
                    <p class="grey--text" style="font-size: 0.5rem">{{ item.confirmerName }}</p>
                  </template>
                  <template v-else>
                    <p class="text-caption">{{ formatDate(item.confirmedAt, "MM/DD HH:mm") }}</p>
                  </template>
                </td>
                <td>
                  <template v-if="item.approvedAt == '点検なし'">
                    <span class="text--secondary">点検なし</span>
                  </template>
                  <template v-else-if="$vuetify.breakpoint.smAndUp">
                    <p class="text-caption">{{ formatDate(item.approvedAt, "MM/DD HH:mm") }}</p>
                    <p class="grey--text" style="font-size: 0.5rem">{{ item.approverName }}</p>
                  </template>
                  <template v-else>
                    <p class="text-caption">{{ formatDate(item.approvedAt, "MM/DD HH:mm") }}</p>
                  </template>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <CheckResultDialog ref="checkResultDialog" @update="updateList" />
  </v-container>
</template>

<script>
import moment from "moment";
import calcDate from "cumin-common/src/mixins/calcDate";
import listCreatable from "../../mixins/listCreatable";
import CheckResultDialog from "../organisms/CheckResultDialog";

export default {
  components: {
    CheckResultDialog,
  },
  mixins: [calcDate, listCreatable],
  data: () => ({
    loading: false,
    month: "",
    filter: {
      isNG: false,
      isUnchecked: false,
      isUnapproved: false,
    },
    items: [],
    headers: [
      { text: "点検日", value: "date", width: 100 },
      { text: "最終点検日時", value: "lastSentAt", sortable: false },
      { text: "OK", value: "okCount", sortable: false, align: "center" },
      { text: "NG", value: "ngCount", sortable: false, align: "center" },
      { text: "日報未点検", value: "uncheckedCount", sortable: false, align: "center" },
      { text: "確認日時", value: "confirmedAt", sortable: false, width: 112 },
      { text: "承認日時", value: "approvedAt", sortable: false, width: 112 },
    ],
    xsHeaders: [
      { text: "点検日", value: "date", width: 74 },
      { text: "最終点検日時", value: "lastSentAt", sortable: false },
      { text: "OK", value: "okCount", sortable: false, align: "center", width: 40 },
      { text: "NG", value: "ngCount", sortable: false, align: "center", width: 40 },
      { text: "未点検", value: "uncheckedCount", sortable: false, align: "center", width: 50 },
      { text: "確認", value: "confirmedAt", sortable: false, width: 58 },
      { text: "承認", value: "approvedAt", sortable: false, width: 70 },
    ],
    selectedRow: null,
    message: "",
    messageDialog: false,
  }),
  created: function () {
    const queryDate = this.$route.query.date;
    this.month = queryDate ? queryDate.substr(0, 7) : moment().format("YYYY/MM");

    const user = this.$store.getters.getUser;
    this.isShowButton = user.authority == "approver" || user.authority == "confirmer";
  },
  activated: async function () {
    this.$emit("created");
    await this.loadList();
  },
  computed: {
    /**
     * テーブルの高さを取得
     * @return {number} 高さ
     */
    tableHeight() {
      const bp = this.$vuetify.breakpoint;
      const height = bp.height - (bp.xs ? 272 : 236);
      return height <= 500 ? 500 : height;
    },

    /**
     * 曜日から文字色を取得
     * @param {date} value
     * @return {string} クラス名
     */
    dayColor() {
      return (value) => {
        if (!value) return;
        moment.locale("ja");
        if (moment(value).format("ddd") == "土") return "blue--text";
        if (moment(value).format("ddd") == "日") return "red--text";
        return "";
      };
    },

    /**
     * フィルター適応時のヘッダー情報取得
     * @return {array} 判定後のヘッダー
     */
    customHeaders() {
      const customHeaders = this.$vuetify.breakpoint.xs ? [...this.xsHeaders] : [...this.headers];
      customHeaders.forEach((header) => {
        header.filter = (value) => {
          if (header.value == "ngCount" && this.filter.isNG) return value > 0;
          if (header.value == "uncheckedCount" && this.filter.isUnchecked) return value > 0;
          if (header.value == "approvedAt" && this.filter.isUnapproved) return value == "";
          return true;
        };
      });
      return customHeaders;
    },
  },

  methods: {
    /**
     * DBから点検結果一覧を取得
     */
    async loadList() {
      this.loading = true;
      const startAt = new Date(this.month + "/01 00:00:00");
      const endAt =
        this.month == moment().format("YYYY/MM")
          ? new Date()
          : new Date(moment(moment(startAt).add(1, "month")).add(-1, "seconds"));

      // リスト作成
      this.items = [];
      this.items = await this.createSummary(startAt, endAt);

      this.loading = false;
    },

    /**
     * 月変更があったら点検結果再読み込み
     * @param {date} date 日時
     */
    updateMonth(date) {
      this.month = date;
      this.loadList();
    },

    /**
     * 確認/承認後のリスト更新
     * @param {Object} data 確認/承認した点検情報
     */
    updateList(data) {
      const index = this.items.findIndex((item) => item.id === data.uid);
      Object.assign(this.items[index], data.item);
    },
  },
};
</script>

<style scoped>
::v-deep .v-input--selection-controls {
  padding: 0;
  margin-right: 16px !important;
  margin-top: 0 !important;
}

::v-deep .v-data-table__wrapper table .v-data-table-header tr th {
  padding: 0 8px;
  height: 48px !important;
  background-color: #f8f8f8 !important;
  white-space: nowrap;
}

::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
  height: 42px !important;
}

.table--xs ::v-deep .v-data-table__wrapper table thead tr th,
.table--xs ::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 4px;
  font-size: 12px;
}

::v-deep .v-data-table__wrapper table thead tr th:first-child,
::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 16px !important;
}

::v-deep .v-data-table__wrapper table thead tr th:last-child,
::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 16px !important;
}

p {
  margin-bottom: 0;
}
</style>
