import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

// stateの初期値としたい任意のデータを定義する
function getDefaultState() {
  return {
    user: {},
    shop: {},
  };
}

// stateを初期化する
export const state = getDefaultState();

export default new Vuex.Store({
  state:
    location.hostname == "localhost"
      ? {
          user: JSON.parse(process.env.VUE_APP_LOCAL_TEST_USER),
          shop: JSON.parse(process.env.VUE_APP_LOCAL_TEST_SHOP),
        }
      : { user: {}, shop: {} },
  getters: {
    getUser: (state) => {
      return state.user;
    },
    getShop: (state) => {
      return state.shop;
    },
  },
  mutations: {
    setUserClaims(state, claims) {
      state.user = JSON.parse(JSON.stringify(claims));
    },
    setShopInfo(state, claims) {
      state.shop = JSON.parse(JSON.stringify(claims));
    },
    clearStateData(state) {
      Object.assign(state, getDefaultState());
    },
    clearUserData(state) {
      const defaultState = getDefaultState();
      Object.assign(state.user, defaultState.user);
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});
