var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"px-2 transparent"},[(!_vm.comments || _vm.comments.length == 0)?_c('div',{staticClass:"pa-2"},[_c('span',{staticClass:"grey--text text-caption"},[_vm._v("特記事項はありません")])]):_vm._e(),_c('v-list',{staticClass:"overflow-y-auto",attrs:{"dense":"","height":_vm.height}},_vm._l((_vm.comments),function(comment,i){return _c('v-list-item',{key:i,staticClass:"pa-2 d-flex align-start",class:_vm.selectedComment == comment.relationCheckResult.uid ? 'tertiary' : '',on:{"click":function($event){return _vm.$emit('click', comment.relationCheckResult.uid)}}},[_c('v-list-item-content',{staticClass:"pa-0 text-body-2"},[_c('v-list-item-title',{staticClass:"text-caption font-weight-light"},[_c('span',{staticClass:"mr-2 text-body-2 font-weight-bold"},[_vm._v(_vm._s(comment.name))]),_c('span',{staticClass:"mr-2 text-caption text--secondary"},[_vm._v(" "+_vm._s(_vm.formatDate(comment.createdAt, "MM/DD HH:mm"))+" ")]),(comment.relationCheckResult.uid)?_c('v-chip',{attrs:{"small":"","color":comment.relationCheckResult.isNormal ? '' : 'red lighten-4'}},[_vm._v(" "+_vm._s(((comment.relationCheckResult.title) + " " + (comment.relationCheckResult.sentAt)))+" ")]):_vm._e()],1),_c('v-list-item-subtitle',{staticClass:"ma-0 pb-2 d-flex justify-space-between"},[_c('div',{staticClass:"text--primary text-wrap",staticStyle:{"max-width":"calc(100% - 100px)","white-space":"pre-wrap !important"}},[_c('span',[_vm._v(_vm._s(comment.content))])]),_c('div',{on:{"click":function($event){$event.stopPropagation();_vm.$refs.imgDialog.openDialog({
                text: ("送信日時：" + (_vm.formatDate(comment.createdAt, 'MM/DD HH:mm')) + "\n" + (comment.content)),
                url: comment.imgFileURL,
                fileName: ((_vm.formatDate(comment.registeredAt, 'YYYYMMDD')) + "_" + (comment.id) + ".jpeg"),
              })}}},[(comment.imgFileURL)?_c('ImgThumbnail',{attrs:{"src":comment.imgFileURL,"size":"60"}}):_vm._e()],1)]),_vm._l((comment.replies),function(reply,i){return [_c('v-list-item-content',{key:((comment.id) + "_" + i),staticClass:"ml-2 px-2 mb-0",staticStyle:{"border-left":"1px solid #0000001f"}},[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-2 text-body-2 font-weight-bold"},[_vm._v(_vm._s(reply.name))]),_c('span',{staticClass:"mr-2 text-caption text--secondary"},[_vm._v(" "+_vm._s(_vm.formatDate(reply.repliedAt, "MM/DD HH:mm"))+" ")])]),_c('v-list-item-subtitle',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"text--primary text-wrap",staticStyle:{"max-width":"calc(100% - 116px)","white-space":"pre-wrap !important"}},[_c('span',[_vm._v(_vm._s(reply.content))])]),_c('div',{on:{"click":function($event){$event.stopPropagation();_vm.$refs.imgDialog.openDialog({
                    text: ("送信日時：" + (_vm.formatDate(reply.repliedAt, 'MM/DD HH:mm')) + "\n" + (reply.content)),
                    url: reply.imgFileURL,
                    fileName: ((_vm.formatDate(comment.registeredAt, 'YYYYMMDD')) + "_" + (comment.id) + "_" + (i + 1) + ".jpeg"),
                  })}}},[(reply.imgFileURL)?_c('ImgThumbnail',{attrs:{"src":reply.imgFileURL,"size":"60"}}):_vm._e()],1)])],1)]})],2)],1)}),1),_c('DialogImg',{ref:"imgDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }